// Web3AuthService.js
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK, UX_MODE } from "@web3auth/base";
import penomoLogo from "../assets/penomo_logo.svg";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
// import { MetamaskAdapter } from "@web3auth/metamask-adapter";

const uiConfig: any = {
  appName: "penomo", // Replace "My App Name" with your application's name.
  logoLight: penomoLogo, // URL to your light mode logo.
  logoDark: penomoLogo,
  mode: "dark",
  primaryColor: "#4CAF50", // Primary color of your app.
  // loginMethodsOrder: ["google", "facebook", "twitter", "reddit"],  // Custom order of social logins.
  modalZIndex: "99999", // Adjust if needed for layering.
  displayErrorsOnModal: true,
  loginGridCol: 3, // Can be 2 or 3 based on how many columns you want in the grid.
  primaryButton: "socialLogin", // Can be 'socialLogin', 'externalLogin', or 'emailLogin'.
};

// const chainConfig = {
//   chainId: "0xaa36a7",
//   rpcTarget: process.env.REACT_APP_RPC_URL || '',
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   blockExplorer: "https://sepolia.etherscan.io/",
//   logo: "https://images.toruswallet.io/eth.svg",
// };

// Mainnet
const chainConfig = {
  chainId: "0x1",
  rpcTarget: process.env.REACT_APP_MAINNET_RPC_URL || "",
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  blockExplorer: "https://etherscan.io/",
  logo: "https://images.toruswallet.io/eth.svg",
};
const clientId = process.env.REACT_APP_WEB3AUTH_ID || "";
const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
// const metamaskAdapter = new MetamaskAdapter({});

const web3auth = new Web3Auth({
  clientId,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  privateKeyProvider,
  uiConfig: uiConfig,
});
// web3auth.configureAdapter(metamaskAdapter);
export { web3auth };