import React, { useContext } from "react";
import { IoLogOutOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "@/provider/AuthContext";
import { web3auth } from "@services/Web3AuthService"; // Import the Web3Auth instance from a separate file
import useStore from "@store/useStore";

const Logout = () => {
  const { logout } = useContext(AuthContext);
  console.log(logout, 'lkasnfladlfn')
  const navigate = useNavigate();
  const { reset } = useStore();

  const handleLogout = async (e: any) => {
    e.preventDefault();
    try {
      if (web3auth.connected) {
        // Check if the user is connected before logging out
        await web3auth.logout();
      }
    } catch (error) {
      console.error("Web3Auth logout error:", error);
    }

    logout(); // Log out from your application
    reset(); // reset store data
    localStorage.removeItem("authToken");
    localStorage.removeItem("web3AuthToken");
    navigate("/"); // Redirect to the home page
    window.location.reload(); //ensure a fresh state
  };

  return (
    <NavLink to={"/"} onClick={handleLogout} className="text-[#FF1E1E] flex items-center text-lg">
      <IoLogOutOutline className="nav-icon" /> &nbsp; Logout
    </NavLink>
  );
};

export default Logout;
