import React, { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Web3 from "web3";
import { AuthContext } from "@/provider/AuthContext";
import { Modal } from "flowbite-react";
import Button from "./UI/Button";
import IcCross from "@/assets/ic_cross";
import { IcDropdownChecked } from "@/assets/menu";
interface IProps {
  closeModal: () => void;
  symbol: string;
}

const WithdrawWallet = ({ symbol, closeModal }: IProps) => {
  const { provider } = useContext(AuthContext);
  // const [tokens, setTokens] = useState<any[]>([]);
  const [selectedToken, setSelectedToken] = useState<any>({ symbol: symbol, balance: 0.0 });
  const [amount, setAmount] = useState<string>("");
  const [destinationAddress, setDestinationAddress] = useState<string>("");
  const [balance, setBalance] = useState<any>("");
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [isTransferring, setIsTransferring] = useState<boolean>(false); // New state variable for transfer status

  // const PENOMO_ADDRESS = "0xF1B3f692a9faB2703637F293Bc2c34a87B532ae4";
  // const PENOMO_ABI = useMemo(() => {
  //   return [
  //     {
  //       inputs: [
  //         {
  //           internalType: "uint256",
  //           name: "initialSupply",
  //           type: "uint256",
  //         },
  //       ],
  //       stateMutability: "nonpayable",
  //       type: "constructor",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "spender",
  //           type: "address",
  //         },
  //         {
  //           internalType: "uint256",
  //           name: "allowance",
  //           type: "uint256",
  //         },
  //         {
  //           internalType: "uint256",
  //           name: "needed",
  //           type: "uint256",
  //         },
  //       ],
  //       name: "ERC20InsufficientAllowance",
  //       type: "error",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "sender",
  //           type: "address",
  //         },
  //         {
  //           internalType: "uint256",
  //           name: "balance",
  //           type: "uint256",
  //         },
  //         {
  //           internalType: "uint256",
  //           name: "needed",
  //           type: "uint256",
  //         },
  //       ],
  //       name: "ERC20InsufficientBalance",
  //       type: "error",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "approver",
  //           type: "address",
  //         },
  //       ],
  //       name: "ERC20InvalidApprover",
  //       type: "error",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "receiver",
  //           type: "address",
  //         },
  //       ],
  //       name: "ERC20InvalidReceiver",
  //       type: "error",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "sender",
  //           type: "address",
  //         },
  //       ],
  //       name: "ERC20InvalidSender",
  //       type: "error",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "spender",
  //           type: "address",
  //         },
  //       ],
  //       name: "ERC20InvalidSpender",
  //       type: "error",
  //     },
  //     {
  //       anonymous: false,
  //       inputs: [
  //         {
  //           indexed: true,
  //           internalType: "address",
  //           name: "owner",
  //           type: "address",
  //         },
  //         {
  //           indexed: true,
  //           internalType: "address",
  //           name: "spender",
  //           type: "address",
  //         },
  //         {
  //           indexed: false,
  //           internalType: "uint256",
  //           name: "value",
  //           type: "uint256",
  //         },
  //       ],
  //       name: "Approval",
  //       type: "event",
  //     },
  //     {
  //       anonymous: false,
  //       inputs: [
  //         {
  //           indexed: true,
  //           internalType: "address",
  //           name: "from",
  //           type: "address",
  //         },
  //         {
  //           indexed: true,
  //           internalType: "address",
  //           name: "to",
  //           type: "address",
  //         },
  //         {
  //           indexed: false,
  //           internalType: "uint256",
  //           name: "value",
  //           type: "uint256",
  //         },
  //       ],
  //       name: "Transfer",
  //       type: "event",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "owner",
  //           type: "address",
  //         },
  //         {
  //           internalType: "address",
  //           name: "spender",
  //           type: "address",
  //         },
  //       ],
  //       name: "allowance",
  //       outputs: [
  //         {
  //           internalType: "uint256",
  //           name: "",
  //           type: "uint256",
  //         },
  //       ],
  //       stateMutability: "view",
  //       type: "function",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "spender",
  //           type: "address",
  //         },
  //         {
  //           internalType: "uint256",
  //           name: "value",
  //           type: "uint256",
  //         },
  //       ],
  //       name: "approve",
  //       outputs: [
  //         {
  //           internalType: "bool",
  //           name: "",
  //           type: "bool",
  //         },
  //       ],
  //       stateMutability: "nonpayable",
  //       type: "function",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "account",
  //           type: "address",
  //         },
  //       ],
  //       name: "balanceOf",
  //       outputs: [
  //         {
  //           internalType: "uint256",
  //           name: "",
  //           type: "uint256",
  //         },
  //       ],
  //       stateMutability: "view",
  //       type: "function",
  //     },
  //     {
  //       inputs: [],
  //       name: "decimals",
  //       outputs: [
  //         {
  //           internalType: "uint8",
  //           name: "",
  //           type: "uint8",
  //         },
  //       ],
  //       stateMutability: "view",
  //       type: "function",
  //     },
  //     {
  //       inputs: [],
  //       name: "name",
  //       outputs: [
  //         {
  //           internalType: "string",
  //           name: "",
  //           type: "string",
  //         },
  //       ],
  //       stateMutability: "view",
  //       type: "function",
  //     },
  //     {
  //       inputs: [],
  //       name: "symbol",
  //       outputs: [
  //         {
  //           internalType: "string",
  //           name: "",
  //           type: "string",
  //         },
  //       ],
  //       stateMutability: "view",
  //       type: "function",
  //     },
  //     {
  //       inputs: [],
  //       name: "totalSupply",
  //       outputs: [
  //         {
  //           internalType: "uint256",
  //           name: "",
  //           type: "uint256",
  //         },
  //       ],
  //       stateMutability: "view",
  //       type: "function",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "to",
  //           type: "address",
  //         },
  //         {
  //           internalType: "uint256",
  //           name: "value",
  //           type: "uint256",
  //         },
  //       ],
  //       name: "transfer",
  //       outputs: [
  //         {
  //           internalType: "bool",
  //           name: "",
  //           type: "bool",
  //         },
  //       ],
  //       stateMutability: "nonpayable",
  //       type: "function",
  //     },
  //     {
  //       inputs: [
  //         {
  //           internalType: "address",
  //           name: "from",
  //           type: "address",
  //         },
  //         {
  //           internalType: "address",
  //           name: "to",
  //           type: "address",
  //         },
  //         {
  //           internalType: "uint256",
  //           name: "value",
  //           type: "uint256",
  //         },
  //       ],
  //       name: "transferFrom",
  //       outputs: [
  //         {
  //           internalType: "bool",
  //           name: "",
  //           type: "bool",
  //         },
  //       ],
  //       stateMutability: "nonpayable",
  //       type: "function",
  //     },
  //   ]
  // }, []);

  useEffect(() => {
    if (provider) {
      const web3 = new Web3(provider);
      const fetchBalances = async () => {
        try {
          const accounts = await web3.eth.getAccounts();
          console.log("Accounts: ",accounts)
          if (accounts.length === 0) return;

          const ethBalance = web3.utils.fromWei(await web3.eth.getBalance(accounts[0]), "ether");
          console.log("ETH Balance: ",ethBalance)
          // const tokenContract = new web3.eth.Contract(PENOMO_ABI as any, PENOMO_ADDRESS);
          // const tokenBalance = web3.utils.fromWei(await tokenContract.methods.balanceOf(accounts[0]).call(), "ether");

          // setTokens([
          //   { symbol: "ETH", balance: ethBalance },
          //   { symbol: "PENOMO", balance: tokenBalance },
          // ]);
          // if(symbol == 'ETH') {
            setSelectedToken({ symbol: "ETH", balance: ethBalance });
            setBalance(ethBalance);
          // }
          // if(symbol == 'PENOMO') {
          //   setSelectedToken({ symbol: "PENOMO", balance: tokenBalance });
          //   setBalance(tokenBalance);
          // }
          
          
        } catch (error) {
          console.error("Error fetching balances:", error);
          toast.error("Error Fetching Balances");
        }
      };

      fetchBalances();
    }
  }, [provider,/* PENOMO_ABI */]);

  // const handleTokenSelect = (token: any) => {
  //   setSelectedToken(token);
  //   setBalance(token.balance);
  //   setAmount(""); // Reset amount when selecting a new token
  // };

  const handleTransfer = async () => {
    if (!provider) return;
    console.log('fnksjdnfkjsdnfs');
    setIsTransferring(true); // Set transferring state to true
    const web3 = new Web3(provider);
    try {
      const accounts = await web3.eth.getAccounts();
      if (parseFloat(amount) > parseFloat(balance)) {
        toast.error("Insufficient balance");
        setIsTransferring(false); // Reset transferring state
        return;
      }

      /*

      let txReceipt;
      if (selectedToken?.symbol === "ETH") {
        txReceipt = await web3.eth.sendTransaction({
          from: accounts[0],
          to: destinationAddress,
          value: web3.utils.toWei(amount, "ether"),
          gas: 21000,
          gasPrice: await web3.eth.getGasPrice(),
        });
      } else {
        const tokenContract = new web3.eth.Contract(PENOMO_ABI as any, PENOMO_ADDRESS);
        const estimatedGas = await tokenContract.methods.transfer(destinationAddress, web3.utils.toWei(amount, "ether")).estimateGas({ from: accounts[0] });
        txReceipt = await tokenContract.methods.transfer(destinationAddress, web3.utils.toWei(amount, "ether")).send({
          from: accounts[0],
          gasPrice: await web3.eth.getGasPrice(),
          gas: estimatedGas,
        });
      }

      setTransactionHash(txReceipt.transactionHash);
      toast.success("Transaction successful. You can check it out on the explorer.");

      */

      const txReceipt = await web3.eth.sendTransaction({
        from: accounts[0],
        to: destinationAddress,
        value: web3.utils.toWei(amount, "ether"),
        gas: 21000,
        gasPrice: await web3.eth.getGasPrice(),
      });

      setTransactionHash(txReceipt.transactionHash);
      toast.success("Transaction successful. You can check it out on the explorer.");


    } catch (error) {
      const err = error as Error;
      console.error("Transaction error:", err);
      toast.error("Transaction failed: " + err?.message);
    } finally {
      setIsTransferring(false); // Reset transferring state
    }
  };

  const explorerLink = `${process.env.REACT_APP_ETHERSCAN_TRANSACTION_URL}${transactionHash}`;

  return (
    <Modal show={true} onClose={closeModal} data-bs-theme="dark" position={"center"} data-testid="withdraw-wallet-modal">
      <Modal.Header className="p-6 bg-monochrome-100 text-white" >
        <h3 className="text-white">Withdraw</h3>
      </Modal.Header>
      <Modal.Body className="p-6 bg-monochrome-100 text-white">
        {/* <div className={"flex justify-between items-center pb-5"}>
          <h3 className="mb-0">Withdraw Wallet</h3>
          <Button classNames="w-[32px] bg-monochrome-5 !px-2 !rounded-xl" disabled={isTransferring} rounded onClick={!isTransferring ? closeModal : undefined}>
            <IcCross />
          </Button>
        </div> */}
        {/* <hr /> */}

        {
          !transactionHash && <>
            {/* <div className="flex gap-4 mt-3">
              {selectedToken === null &&
                tokens.map((token) => (
                  <Button rounded secondary key={token.symbol} onClick={() => handleTokenSelect(token)} disabled={isTransferring}>
                    {token.symbol}: {token.balance}
                  </Button>
                ))}
            </div> */}

            {selectedToken && (
              <div className="flex flex-col gap-4">
                <p className="mb-3">
                  {selectedToken.symbol}: {balance}
                </p>
                <input
                  type="text"
                  placeholder={`Amount (${selectedToken.symbol})`}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  disabled={isTransferring}
                />
                <input
                  type="text"
                  placeholder="Destination Address"
                  value={destinationAddress}
                  onChange={(e) => setDestinationAddress(e.target.value)}
                  disabled={isTransferring}
                />
                <div className="flex flex-col gap-4 mt-3">
                  <Button
                    rounded secondary classNames="!py-0 w-full"
                    onClick={handleTransfer}
                    disabled={!amount || !destinationAddress || parseFloat(balance) > parseFloat(amount) || isTransferring}
                  >
                    {isTransferring ? "Transferring..." : "Transfer"}
                  </Button>
                  {/* <Button rounded secondary classNames="w-full" onClick={() => setSelectedToken(null)} disabled={isTransferring}>
                    Choose Another Token
                  </Button> */}
                </div>
              </div>
            )}
          </>
        }

        {transactionHash && (
          <section className="p-4 flex flex-col gap-7 items-center">
            <IcDropdownChecked className={"h-10 w-10 bg-status-approved rounded-full p-2"} />
            <div className="flex flex-col gap-2 items-center">
              <h3>Withdrawal Successful</h3>
              <p className="text-sm w-[90%] text-center">Your transaction was successful. You can view the transaction details on the block explorer.</p>
            </div>
            <Button primary rounded onClick={() => window.open(explorerLink, "_blank")} classNames="w-full">
              View Transaction
            </Button>
            <Button primary rounded onClick={closeModal} classNames="w-full">
              Close
            </Button>
          </section>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawWallet;
