import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Importing page components and ProtectedRoute
import DashboardPage from "./pages/DashboardPage";
import HomePage from "./pages/HomePage";
import SettingsPage from "./pages/SettingsPage";
import TokenSale from "./pages/TokenSale";
import TransactionsPage from "./pages/TransactionsPage";

// Importing common components
import NavBar from "./components/NavBar";

// Importing auth services
import ProtectedRoute from "./services/ProtectedRoute";

// Importing styling
import Marketplace from "./pages/Marketplace";
import { ROUTES } from "./constants";
import Header from "./components/Header";
import TransactionDetailSideView from "./components/Page/Transactions/TransactionDetailSideView";
import ContactSupportPage from "./pages/ContactSupportPage";
import StakingCampaignPage from "./pages/StakingCampaignPage";
import MarketplaceDetails from "./components/Page/Marketplace/MarketplaceDetails";
import Notifications from "./pages/Notifications";
import { fetchUser } from "./services/api";
import useStore from "./store/useStore";
import { ITransaciton } from "./types/transactions";
import useAuth from "./hooks/useAuth";

// lazy loading
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

const Layout = () => {
  /**
   * To Show the transaction details on right side
   */
  const [selectedTransaction, setSelectedTransaction] = useState<ITransaciton | null>(null);
  const { pathname } = useLocation();
  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
  const isMarketplaceDetailsPage = pathname === ROUTES.MARKETPLACEDETAILS;
  const isHomePage = pathname === ROUTES.APP_ROOT;
  // Used to detect the route change and hide the transaciton view
  const location = useLocation();
  const { userId } = useAuth();
  const { userData, setUser } = useStore();
  React.useEffect(() => {
    setSelectedTransaction(null);
  }, [location]);

  useEffect(() => {
    if (userData._id && localStorage.getItem("authToken")) {
      fetchUser(userData._id)
        .then((res: any) => {
          if (res.data) {
            setUser(res.data);
          }
        })
        .catch(() => {});
    }
  }, []);
  const haveMargin = () => {
    if (isSetupProfilePage) {
      return false;
    }
    if (!userId) {
      return false;
    }
    return true;
  };
  return (
    <main className="flex flex-col lg:flex-row mx-auto relative h-screen">
      <NavBar />
      <section className={`flex flex-col w-full ${haveMargin() ? "xl:ml-72" : "xl:ml-0"} ${isHomePage ? "bg-[#1A1C22]" : "bg-monochrome-60"} h-full`}>
        <Header />
        <div className={` ${!userId ? "mx-0" : "px-3 pt-6"} p-${isMarketplaceDetailsPage ? 0 : 0} ${haveMargin() ? "h-full" : "h-[calc(100%-2rem)]"} `}>
          <Routes>
            <Route path={ROUTES.APP_ROOT} element={<HomePage />} />
            <Route
              path={ROUTES.DASHBOARD}
              element={
                <ProtectedRoute>
                  <DashboardPage selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.MARKETPLACE}
              element={
                <ProtectedRoute>
                  <Marketplace />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.MARKETPLACEDETAILS}
              element={
                <ProtectedRoute>
                  <MarketplaceDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.TRANSACTIONS}
              element={
                <ProtectedRoute>
                  <TransactionsPage selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.CONTACTSUPPORT}
              element={
                <ProtectedRoute>
                  <ContactSupportPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.TOKEN_SALE}
              element={
                <ProtectedRoute>
                  <TokenSale />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.SETTINGS}
              element={
                <ProtectedRoute>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.STAKINGCAMPAIGN}
              element={
                <ProtectedRoute>
                  <StakingCampaignPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.PROFILE_SETUP}
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.NOTIFICATIONS}
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <NotFoundPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </section>
      {selectedTransaction && <TransactionDetailSideView transaction={selectedTransaction} onClose={() => setSelectedTransaction(null)} />}
    </main>
  );
};

export default Layout;
