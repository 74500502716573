import React, { useEffect, useState } from "react";
import { fetchDocs } from "@services/api";
import { useQuery } from "@tanstack/react-query";

const ProjectDocs = ({ id }: { id: string}) => {
  const [documents, setDocuments] = useState([]);

  const { data: project, isLoading } = useQuery({
    queryKey: ["getDocs", id],
    queryFn: () => fetchDocs(id),
    enabled: !!id,
  });

  useEffect(() => {
    if (project && Object.keys(project).length > 0) {
      const loadedDocuments = [
        {
          name: "Financial Projections",
          fieldName: "financialProjections",
          status: project.financialProjections?.[0]?.status || "Not Submitted",
        },
        {
          name: "Taxation And Incentives",
          fieldName: "taxationAndIncentives",
          status: project.taxationAndIncentives?.[0]?.status || "Not Submitted",
        },
        {
          name: "Funding Agreements",
          status: project.fundingAgreements?.[0]?.status || "Not Submitted",
          fieldName: "fundingAgreements",
        },
        {
          name: "Insurance Policies",
          status: project.insurancePolicies?.[0]?.status || "Not Submitted",
          fieldName: "insurancePolicies",
        },
        {
          name: "Land Agreements",
          status: project.landAgreements?.[0]?.status || "Not Submitted",
          fieldName: "landAgreements",
        },
        {
          name: "Power Purchase Agreements",
          status: project.powerPurchaseAgreements?.[0]?.status || "Not Submitted",
          fieldName: "powerPurchaseAgreements",
        },
        {
          name: "Joint Venture Or Partnership Agreements",
          status: project.jointVentureOrPartnershipAgreements?.[0]?.status || "Not Submitted",
          fieldName: "jointVentureOrPartnershipAgreements",
        },
        {
          name: "Intellectual Property Rights",
          status: project.intellectualPropertyRights?.[0]?.status || "Not Submitted",
          fieldName: "intellectualPropertyRights",
        },
        {
          name: "Social Impact Assessments",
          status: project.socialImpactAssessments?.[0]?.status || "Not Submitted",
          fieldName: "socialImpactAssessments",
        },
        {
          name: "Sustainability Certifications",
          status: project.sustainabilityCertifications?.[0]?.status || "Not Submitted",
          fieldName: "sustainabilityCertifications",
        },
        {
          name: "Operation And Maintenance Plans",
          status: project.operationAndMaintenancePlans?.[0]?.status || "Not Submitted",
          fieldName: "operationAndMaintenancePlans",
        },
        {
          name: "Performance Data",
          status: project.performanceData?.[0]?.status || "Not Submitted",
          fieldName: "performanceData",
        },
        {
          name: "Feasibility Studies",
          fieldName: "feasibilityStudies",
          status: project.feasibilityStudies?.[0]?.status || "Not Submitted",
        },
        {
          name: "Healthy And Safety Plans",
          status: project.healthAndSafetyPlans?.[0]?.status || "Not Submitted",
          fieldName: "healthAndSafetyPlans",
        },
        {
          name: "Environmental Impact Assessments",
          status: project.environmentalImpactAssessments?.[0]?.status || "Not Submitted",
          fieldName: "environmentalImpactAssessments",
        },
        {
          name: "Biodiversity Studies",
          status: project.biodiversityStudies?.[0]?.status || "Not Submitted",
          fieldName: "biodiversityStudies",
        },
      ];
      setDocuments(loadedDocuments);
    }
  }, [project]);

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left project-docs">
        <thead className="text-xs uppercase bg-gray-950">
          <tr>
            <th scope="col" className="px-4 py-3">
              Document Type
            </th>
            <th scope="col" className="px-4 py-3">
              Status
            </th>
            <th scope="col" className="px-4 py-3">
              File
            </th>
            <th scope="col" className="px-4 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document, index) => (
            <tr key={index} className="bg-gray-900 border-b">
              <th scope="row" className="px-4 py-4 font-medium">
                {document.name}
              </th>
              <td className="p-4">
                <span
                  data-testid={`${document.fieldName}`}
                  className={`text-sm font-medium px-2.5 py-0.5 rounded whitespace-nowrap ${
                    document.status === "Not Submitted"
                      ? "bg-red-400 text-gray-800"
                      : document.status === "Pending"
                      ? "bg-yellow-200 text-yellow-900"
                      : document.status === "Accepted"
                      ? "bg-green-200 text-green-900"
                      : document.status === "Incomplete"
                      ? "bg-gray-400 text-gray-800"
                      : "bg-red-200 text-red-900"
                  }`}
                >
                  {document.status}
                </span>
              </td>
              <td className="px-4 py-4">{document.fieldName}</td>
              <td className="px-4 py-4">
                <button type="button" className="btn-penomo">
                  View
                </button>
              </td>
            </tr>
          ))}

          {documents.length === 0 && !isLoading && (
            <tr className="bg-gray-900">
              <td colSpan="4" className="text-center p-4">
                No documents found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectDocs;
