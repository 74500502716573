import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { AuthContext } from "@/provider/AuthContext";
import Loader from "./Loader";
import Button from "./UI/Button";
import { IcTopUp, IcWithdrow } from "@/assets";
import TopUpWallet from "./TopUpWallet";
import WithdrawWallet from "./WithdrawWallet";

const BalanceCard = () => {

  const { provider } = useContext(AuthContext);
  const [balance, setBalance] = useState<string>("0.00");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showTopUp, setShowTopUp] = useState<boolean>(false);
  const [showWithdraw, setShowWithdraw] = useState<boolean>(false);
  const [symbol, setSymbol] = useState("")

  useEffect(() => {
    if (!provider) {
      console.error("Web3Auth provider is not available");
      setIsLoading(false);
      return;
    }

    const web3 = new Web3(provider);

    const fetchBalance = async () => {
      const accounts = await web3.eth?.getAccounts();
      console.log("Accounts: ",accounts)
      if (!accounts || accounts?.length === 0) {
        console.error("No account found.");
        setIsLoading(false);
        return;
      }

      const walletAddress = accounts?.[0];
      console.log("Wallet Adddress: ",walletAddress)
      const balanceInWei = await web3.eth?.getBalance(walletAddress);
      console.log("Balance in Wei: ",balanceInWei)
      const balanceInEth = web3.utils?.fromWei(balanceInWei, "ether");
      console.log("Balance in ETH: ",balanceInEth)
      setBalance(balanceInEth);
      setIsLoading(false);
    };

    fetchBalance();
  }, [provider]);

  const handleTopUpClick = () => {
    setShowTopUp(true);
  };

  const handleWithdrawClick = (symbol: string) => {
    setShowWithdraw(true); // Ensure this state is defined and managed properly
    setSymbol(symbol)
  };

  return (
    <div className="bg-monochrome-100 p-4 flex flex-column rounded-lg">
      <div className="flex flex-row gap-4 items-start">
        <img src="./assets/ic_wallet.svg" />
        <div className="flex flex-column gap-2">
        <p className="text-[#9E9E9E] text-[0.688rem]">Wallet Balance</p>
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <span className="font-medium text-[2rem]">
              {balance} <sub className="text-monochrome-20 text-base relative bottom-0">ETH</sub>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row mt-4 gap-4">
        <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal" onClick={handleTopUpClick}>
          <IcTopUp />
          Top Up
        </Button>
        <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal" onClick={() => handleWithdrawClick("ETH")}>
          <IcWithdrow />
          Withdraw
        </Button>

        {/* <Button primary rounded classNames="w-full sm:w-4/12" onClick={() => handleWithdrawClick("PENOMO")}>
          <IcWithdrow />
          Withdraw PNOMO
        </Button> */}
      </div>

      {showTopUp && <TopUpWallet closeModal={() => setShowTopUp(false)} />}
      {showWithdraw && <WithdrawWallet symbol={symbol} closeModal={() => setShowWithdraw(false)} />}
    </div>
  );
};

export default BalanceCard;
