import AccountDetails from "@/components/Page/Settings/AccountDetails";
import NotificationSettings from "@/components/Page/Settings/NotificationSettings";
import { AuthContext } from "@/provider/AuthContext";
import useStore from "@store/useStore";
import { jwtDecode } from "jwt-decode";
import { useContext } from "react";
import { useState } from "react";

const settingComponents: any = {
  account: {
    title: "Account",
    component: <AccountDetails />
  },
  notification: {
    title: "Notification",
    component: <NotificationSettings />
  }
}
const SettingsPage = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { investorData } = useStore();

  const { authToken } = useContext(AuthContext);

  const { userId, investorId } = jwtDecode(authToken)
  const [selectedComponent, setSelectedComponent] = useState<{ link: string, components: any }>({
    link: "account",
    components: <AccountDetails />
  });
  return (
    <>
       <div className="flex flex-col xl:flex-row divide-y xl:divide-y-0 xl:divide-x divide-monochrome-40 h-full">
        <div className="flex xl:flex-col w-[300px] pr-4 !pb-4 xl:pb-0">
          {
            Object.keys(settingComponents).map((key: string, index: number) => {
              return (
                <div key={index} className={`p-3 text-[0.688rem] cursor-pointer font-sm ${key == selectedComponent.link ? 'bg-monochrome-100 rounded-[0.5rem] font-bold' : ''}`} onClick={() => {
                  setSelectedComponent({
                    link: key,
                    components: settingComponents[key].component
                  })
                }}>
                  <span>{settingComponents[key].title}</span>
                </div>
              )
            })
          }
        </div>
        <div className="pt-3 !xl:pt-0 xl:p-4 w-full">
          {
            selectedComponent.components && selectedComponent.components
          }
        </div>
      </div>
    </>

  );
};

export default SettingsPage;


