import { fetchTransactions } from "@/services/api";
import Button from "../../UI/Button";
import Loader from "../../Loader";
import { useQuery } from "@tanstack/react-query";
import TransactionItem from "./TransactionItem";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { ITransaciton } from "@/types/transactions";

interface ITransactionsProps {
    investorId: string;
    selectedTransaction: ITransaciton | null;
    setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
}

const RecentTransactions = ({ investorId, selectedTransaction, setSelectedTransaction }: ITransactionsProps) => {
    const navigate = useNavigate();

    const { data: transactions, isLoading: isLoadingTransactions } = useQuery({
        queryKey: ["getTransactions", investorId],
        queryFn: () => fetchTransactions(investorId),
        enabled: !!investorId,
        select: (data) => data.data
    });

    const navigateToAllTransactions = () => {
        navigate(`/transactions`);
    };

    return (
        <div className="bg-monochrome-100 p-4 flex flex-column rounded-lg">
           <div className="flex justify-between">
                <h5 className="font-medium">Recent Transactions</h5>
                <Button classNames="text-green-500 justify-end pr-0" onClick={navigateToAllTransactions}>
                    View All
                </Button>
            </div>
            <div className="overflow-x-auto sm:overflow-hidden">
                <div className="mt-4 w-[200%] sm:w-full">
                    <div className="grid grid-cols-5 text-monochrome-20 pb-6 pt-2 border-b border-monochrome-40">
                        <span className="text-[0.688rem] text-[#9E9E9E]">Type</span>
                        <span className="text-end text-[0.688rem] text-[#9E9E9E]">Symbols</span>
                        <span className="text-end text-[0.688rem] text-[#9E9E9E]">Date</span>
                        <span className="text-end text-[0.688rem] text-[#9E9E9E]">Time</span>
                        <span className="text-end text-[0.688rem] text-[#9E9E9E]">Amount</span>
                    </div>
                    {isLoadingTransactions && (
                        <div className="center min-h-20">
                            <Loader isLoading={true} />
                        </div>
                    )}
                    {
                        !isLoadingTransactions && transactions && transactions.length > 0 && (
                            transactions.slice(0, 5).map((transaction: any, index: number) => (
                                <TransactionItem
                                    key={index}
                                    isSelected={selectedTransaction?.hash === transaction.hash}
                                    item={transaction}
                                    onClick={() => {
                                        setSelectedTransaction(transaction);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                            ))
                        )
                        }
                    {!isLoadingTransactions && transactions && transactions.length === 0 && (
                        <div className="center min-h-40 text-[0.75rem]">
                            No transactions available.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecentTransactions;
