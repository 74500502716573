import React from "react";
import { formatNumber } from "@/utils";

interface IStepThreeForm {
  fundingGoal: number;
  tokenAmount: number;
  tokenPrice: number;
  fundingUsage: {
    amount: number;
    description: string;
  }[];
  setFundingUsage: (fundingUsage: { amount: number; description: string }[]) => void;
  revenueShare: number;
  contractStartDate: string;
  contractTerm: number;
}

const StepThreeForm = ({
  fundingGoal,
  tokenAmount,
  tokenPrice,
  fundingUsage,
  setFundingUsage,
  revenueShare,
  contractStartDate,
  contractTerm,
}: IStepThreeForm) => {
  return (
    <div className="step3">
      <div className="form-group">
        <label htmlFor="fundingGoal">Funding Goal: ${formatNumber(fundingGoal ?? 0)}</label>
      </div>
      <div className="form-group">
        <label htmlFor="tokenAmount">Token Amount: ${formatNumber(tokenAmount ?? 0)}</label>
      </div>
      <div className="form-group">
        <label htmlFor="tokenPrice">Token Price: ${formatNumber(tokenPrice || 500)}</label>
      </div>
      <div className="form-group">
        <label htmlFor="revenueShare">Revenue Share: {revenueShare}%</label>
      </div>
      <div className="form-group">
        <label htmlFor="contractStartDate">Contract Start Date: {contractStartDate}</label>
      </div>
      <div className="form-group">
        <label htmlFor="contractTerm">Contract Term: {contractTerm} Months</label>
      </div>
      <div>
        <h4>Funding Usage</h4>
      </div>
      {fundingUsage.map((usage, index) => (
        <div key={index} className="funding-usage-group">
          <div className="form-group">
            <label htmlFor={`fundingUsageAmount-${index}`}>Amount: ${formatNumber(usage?.amount ?? 0)}</label>
          </div>
          <div className="form-group">
            <label htmlFor={`fundingUsageDescription-${index}`}>Description: {usage.description}</label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepThreeForm;
