import { IcPeaqLogo, IcPeaqReward, IcPenomoWhitelistJoined, IcWarning } from '@/assets';
import IcCross from '@/assets/ic_cross';
import Loader from '@/components/Loader';
import Button from '@/components/UI/Button';
import useUnload from '@/components/useUnload';
import useAuth from '@/hooks/useAuth';
import { fetchUser, fetchInvestor, withdrowPeawReward } from '@/services/api';
import { IUser } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { Modal } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { GoArrowUpRight } from "react-icons/go";

const transactionLink = process.env.REACT_APP_PEAQ_TRANSACTION_LINK;

interface ClaimReaqRewardModalProps {
    handleClose: () => void;
    user: IUser;
}

enum RenderStatus {
    NOT_STARTED = "NOT_STARTED",
    DENIED = "DENIED",
    SUCCESS = "SUCCESS",
    REWARD_ALREADY_CLAIMED = "REWARD_ALREADY_CLAIMED",
}

const ClaimReaqRewardModal = ({ handleClose, user }: ClaimReaqRewardModalProps) => {
    const { userId, investorId } = useAuth();

    const { data: userData, isLoading: isLoadingUser } = useQuery({
        queryKey: ["getUser", userId],
        queryFn: () => fetchUser(userId),
        enabled: !!userId,
        select: (data) => data.data,
    });

    const { data: investorData, isLoading: isLoadingInvestor } = useQuery({
        queryKey: ["getInvestor", investorId],
        queryFn: () => fetchInvestor(investorId),
        enabled: !!investorId,
        select: (data) => data.data,
    });

    console.log("User Data: ", userData);
    console.log("Investor Data: ", investorData);

    const [renderStatus, setRenderStatus] = useState<RenderStatus>(RenderStatus.NOT_STARTED);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hash, setHash] = useState("");

    useUnload((e: any) => {
        if (isSubmitting) {
            e.preventDefault();
            e.returnValue = 'PEAQ Reward Transaction is in progress, please wait.';
        } else {
            return null;
        }
    });

    useEffect(() => {
        if (investorData?.isPeaqRewardClaimed) {
            setRenderStatus(RenderStatus.REWARD_ALREADY_CLAIMED);
        }
    }, [investorData]);

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const response = await withdrowPeawReward(investorId, { email: user.email || "", walletAddress: userData.walletDetails.web3authWalletAddress });

            setIsSubmitting(false);
            if (response.code == 200) {
                setHash(response.data.hash);
                setRenderStatus(RenderStatus.SUCCESS);
            } else {
                toast.error('Failed to claim reward, please try again');
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to claim reward, please try again');
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show={true} onClose={handleClose} position={'center'} size={'2xl'}>
            <Modal.Body className="p-4 bg-monochrome-100">
                <div className="flex justify-between items-center pb-5">
                    <h1 className="mb-0">PEAQ Rewarding Campaign</h1>
                    <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" rounded
                        onClick={handleClose} disabled={isSubmitting}>
                        <IcCross />
                    </Button>
                </div>
                <hr className="border-monochrome-40" />
                {renderStatus === RenderStatus.NOT_STARTED && !isSubmitting && (
                    <section className="p-4 flex flex-col gap-7 items-center">
                        <p className='text-sm'>Have you registered with this email on Galxe for the PEAQ rewarding campaign?: <span className='text-green-500'>{user?.email}</span></p>
                        <div className='flex flex-col w-full gap-4'>
                            <Button primary rounded onClick={() => {
                                if (userData?.walletDetails?.peaqWalletAddress != null) {
                                    setRenderStatus(RenderStatus.REWARD_ALREADY_CLAIMED);
                                } else {
                                    handleSubmit();
                                }
                            }} classNames="w-full">Yes</Button>
                            <Button primary rounded onClick={() => setRenderStatus(RenderStatus.DENIED)} classNames="w-full">No</Button>
                        </div>
                    </section>
                )}
                {isSubmitting && (
                    <section className="p-8 flex flex-col gap-10 items-center">
                        <IcPeaqLogo />
                        <Loader isLoading={true} />
                        <p className="text-sm">Rewarding your PEAQ Tokens to your Wallet</p>
                    </section>
                )}
                {renderStatus === RenderStatus.DENIED && (
                    <section className="p-4 flex flex-col gap-7 mt-4 items-center">
                        <IcWarning className="text-monochrome-20" />
                        <p className='text-medium leading-normal text-center'>Please register on Galxe PEAQ campaign with this email address and come back to claim your rewards</p>
                        <div className='flex flex-col w-full gap-4'>
                            <Button primary rounded onClick={handleClose} classNames="w-full">Close</Button>
                        </div>
                    </section>
                )}
                {renderStatus === RenderStatus.SUCCESS && (
                    <section className="p-4 flex flex-col gap-7 mt-4 items-center">
                        <IcPeaqReward className="w-[60px] bg-green-10 rounded-full text-green-500" />
                        <div className='flex flex-col items-center text-center gap-2'>
                            <h3>You have claimed your PEAQ tokens</h3>
                            <p className='text-sm text-monochrome-20'>You will receive them on your wallet that is connected on Galxe </p>
                            {hash && <a target='_blank' href={`${transactionLink}${hash}`} rel="noreferrer" className='text-green-500 flex text-center py-3 mt-3 '>View Transaction on BlockExplorer <GoArrowUpRight className='text-lg' /> </a>}
                        </div>
                        <div className='flex flex-col w-full gap-4 mt-8'>
                            <Button primary rounded classNames="w-full" onClick={handleClose}>Close</Button>
                        </div>
                    </section>
                )}
                {renderStatus === RenderStatus.REWARD_ALREADY_CLAIMED && (
                    <section className="p-4 flex flex-col gap-7 mt-4 items-center">
                        {/* <IcWarning className="text-monochrome-20" /> */}
                        <IcPenomoWhitelistJoined className="text-green-500" />
                        <p className='text-medium leading-normal text-center'>You have already claimed your Peaq rewards</p>
                        <div className='flex flex-col w-full gap-4'>
                            <Button primary rounded onClick={handleClose} classNames="w-full">Close</Button>
                        </div>
                    </section>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ClaimReaqRewardModal;
