import { Container, Row, Col, Button } from 'react-bootstrap';
import './InvestmentCard.css';
// import iconWallet from '../../../../../assets/iconWallet.png'
import iconWallet1 from '../../../../../assets/iconWallet1.png'
import iconWallet2 from '../../../../../assets/iconWallet2.png'
import iconWallet3 from '../../../../../assets/iconWallet3.png'
import twemoji_flag from '../../../../../assets/twemoji_flag-germany.png'

interface InvestmentCard {
    companyData: any
}

const InvestmentCard = ({ companyData }: InvestmentCard) => {
    const { name, fundingGoal, tokenPrice, irr } = { ...companyData?.data[0] }

    return (
        <Container className="investment-card">
            <Row className="header tab-container" style={{ gap: '10px' }}>
                <Col className="tab">Energy Sales PPA</Col>
                <Col className="tab">Battery Storage</Col>
                <Col className="tab">Carbon Credits</Col>
            </Row>
            <Row className="content">
                <Col>
                    <div className="title">{name}</div>
                    <div className='flex items-center' style={{ gap: "100px" }}>
                        <div className="subtitle text-gray">Battery Storage</div>
                        <div className="country text-gray flex items-center" style={{ gap: "10px" }}>
                            <span role="img" aria-label="Germany">
                                <img style={{ width: "26px", height: "26px" }} src={twemoji_flag} alt='twemoji_flag' />
                            </span> German
                        </div>
                    </div>
                    <Button variant="success" className="invest-button">Invest Now</Button>
                </Col>
                <Col>
                    <div className="info">
                        <div className='flex' style={{ gap: "10px" }}>
                            <img style={{ width: "26px", height: "26px" }} src={iconWallet3} alt='iconWallet3' />
                            <div className="info-item">
                                <span className="info-label text-gray flex">
                                    Token Price
                                </span>
                                <span className="info-value">${tokenPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>
                        </div>
                        <div className='flex' style={{ gap: "10px" }}>
                            <img style={{ width: "26px", height: "26px" }} src={iconWallet2} alt='iconWallet3' />
                            <div className="info-item">
                                <span className="info-label text-gray flex">
                                    Total Volume
                                </span>
                                <span className="info-value">${fundingGoal?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>
                        </div>
                        <div className='flex' style={{ gap: "10px" }}>
                            <img style={{ width: "26px", height: "26px" }} src={iconWallet1} alt='iconWallet3' />
                            <div className="info-item">
                                <span className="info-label text-gray flex">
                                    APY
                                </span>
                                <span className="info-value">{irr}%</span>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default InvestmentCard;
