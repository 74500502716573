import React from "react";
import { formatNumber } from "@/utils";

interface IStepTwoForm {
  financingStructure: string;
  netPresentValue: number;
  internalRateOfReturn: number;
  levelizedCostOfEnergy: number;
  paybackPeriod: number;
  capacityFactor: number;
  revenueStreams: {
    name: string;
    amount: number;
    details: string;
  }[];
  operationCosts: number;
  operationStart: string;
  operationLifetime: number;
}

const StepTwoForm = ({
  financingStructure,
  netPresentValue,
  internalRateOfReturn,
  levelizedCostOfEnergy,
  paybackPeriod,
  capacityFactor,
  revenueStreams,
  operationCosts,
  operationStart,
  operationLifetime,
}: IStepTwoForm) => {
  return (
    <div className="step2">
      <div>
        <div className="form-group">
          <label htmlFor="financingStructure">Financing Structure: {financingStructure}</label>
        </div>
        <div className="form-group">
          <label htmlFor="netPresentValue">Net Present Value: ${formatNumber(netPresentValue ?? 0)}</label>
        </div>
        <div className="form-group">
          <label htmlFor="internalRateOfReturn">Internal Rate of Return: {internalRateOfReturn}%</label>
        </div>
        <div className="form-group">
          <label htmlFor="levelizedCostOfEnergy">Levelized Cost of Energy ($/MWh): ${formatNumber(levelizedCostOfEnergy ?? 0)} </label>
        </div>
        <div className="form-group">
          <label htmlFor="paybackPeriod">Payback Period: {paybackPeriod} Years</label>
        </div>
        <div className="form-group">
          <label htmlFor="capacityFactor">Capacity Factor: {capacityFactor}%</label>
        </div>

        <div className="form-group">
          <label htmlFor="operationCosts">Operation Costs ($/year): ${formatNumber(operationCosts ?? 0)}</label>
        </div>
        <div className="form-group">
          <label htmlFor="operationStart">Operation Start Date: {operationStart}</label>
        </div>
        <div className="form-group">
          <label htmlFor="operationLifetime">Operation Lifetime: {operationLifetime} Years</label>
        </div>
      </div>
      <div className="text-left">
        <h4>Revenue Streams</h4>
      </div>
      <div className="form-body">
        {revenueStreams.map((stream, index) => (
          <div key={index} className="form-separate group">
            <div className="form-group">
              <label htmlFor={`revenueName-${index}`}>Name: {stream.name}</label>
            </div>
            <div className="form-group">
              <label htmlFor={`revenueAmount-${index}`}>Amount ($/year): ${formatNumber(stream.amount ?? 0)}</label>
            </div>
            <div className="form-group">
              <label htmlFor={`revenueDetails-${index}`}>Details: {stream.details}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepTwoForm;
