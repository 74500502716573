import { Modal } from 'flowbite-react';
import React from 'react'
import Button from './Button';
import IcCross from '@/assets/ic_cross';
interface GeneralInfoDialogProps {
    title: string;
    message: string;
    isShowCloseButton?: boolean;
    yesTitle?: string;
    noTitle?: string;
    onButtonClick: (types: string) => void;
}
const GeneralInfoDialog = ({ title, message, isShowCloseButton = false, yesTitle, noTitle = "Close", onButtonClick }: GeneralInfoDialogProps) => {
    return (
        <Modal show={true} onClose={() => { onButtonClick("close") }} position={"center"} size={"2xl"}>
            <Modal.Body className={`p-4 bg-monochrome-100 rounded`}>
                {
                    title != '' && <>
                        <div className={"flex justify-between items-center pb-5"}>
                            <h1 className="mb-0">{title}</h1>
                            {
                                isShowCloseButton && <Button classNames="w-[32px] bg-monochrome-5 border border-monochrome-10 !px-2 !rounded-xl" rounded onClick={!isTransferring ? handleClose : undefined}>
                                    <IcCross />
                                </Button>
                            }
                        </div>
                        <hr />
                    </>
                }
                <section className="p-4 flex flex-col gap-7">
                    <div className="flex flex-col gap-2 items-center">
                        <p className="text-sm w-[90%] text-center" dangerouslySetInnerHTML={{ __html: message }}></p>
                    </div>
                    <div className='flex gap-4'>
                        {
                            yesTitle && <Button rounded onClick={() => { onButtonClick("yes") }} classNames="w-full">
                                {yesTitle}
                            </Button>
                        }
                        <Button primary rounded onClick={() => { onButtonClick("close") }} classNames="w-full">
                            {noTitle}
                        </Button>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}

export default GeneralInfoDialog