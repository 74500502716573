import { http } from "@services/http";
import { ENDPOINT } from "@constants";

export const fetchUser = (id: string) => http.get(`${ENDPOINT.USER}/${id}`).then((res) => res.data);
export const fetchInvestor = (id: string) => http.get(`${ENDPOINT.INVESTOR}/${id}`).then((res) => res.data);
export const fetchTransactions = (id: string) => http.get(`${ENDPOINT.TRANSACTIONS}/${id}`).then((res) => res.data);
export const fetchDocs = (id: string) => http.get(`${ENDPOINT.DOCS}/${id}`).then((res) => res.data);
export const fetchAllProjects = () => http.get(ENDPOINT.PROJECTS).then((res) => res.data);
export const fetchAllNotifications = (id: string) => http.get(`${ENDPOINT.NOTIFICATION}/${id}`).then((res) => res.data);
export const readNotification = (id: string) => http.patch(`${ENDPOINT.READ_NOTIFICATION}/${id}`).then((res) => res.data);
export const fetchProjectById = (id: string) => http.get(`${ENDPOINT.PROJECTSBYID}/${id}`).then((res) => res.data);
export const withdrowPeawReward = (investorId: string, data: any) => http.post(`${ENDPOINT.INVESTOR}/rewards/peaqCampaign/${investorId}`, data).then((res) => res.data);
export const joinPenomoPreSale = (userId: string, data: any) => http.post(`${ENDPOINT.USER}/whitelist/wallet/${userId}`, data).then((res) => res.data)
export const updateUser = (id: string, data: any) => http.patch(`${ENDPOINT.USER}/${id}`, data).then((res) => res.data);
