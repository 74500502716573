import useAuth from "@/hooks/useAuth";
import Button from "./UI/Button";
import { IcArrowUpFill } from "@/assets";

interface KycVerificationBannerProps {
  isVerified: boolean;
}

const KycVerificationBanner = ({ isVerified }: KycVerificationBannerProps) => {
  const { userId } = useAuth();
  const company_app_id = process.env.REACT_APP_SILT_COMPANY_APP_ID
  if (isVerified) {
    return null;
  }

  const handleVerifyClick = () => {
    window.open(
      `https://signup.getsilt.com/?company_app_id=${company_app_id}&customer_user_id=${userId}&hide_redirect_button=true`,
      "_blank"
    );
  };

  return (
    <section
      className="col-span-2 bg-[#1A1C22] px-4 py-4 mb-4 flex flex-col sm:flex-row gap-4 sm:gap-0 border-[0.5px] rounded-lg border-danger-100"
      style={{ boxShadow: "0px 4px 15px rgba(204, 0, 0, 0.2" }}
    >
      <div className="flex grow">
        <img src="./assets/ic_kyc_incomplete.svg" />
        <div className="flex flex-column ml-4">
          <h4 className="font-medium text-white">Complete KYC</h4>
          <p className="mt-2 font-normal text-monochrome-20  text-[0.688rem]">Complete your KYC to access the PNMO presale.</p>
        </div>
      </div>
      <Button
        classNames="md:w-[250px] lg:w-[200px] xl:w-auto !text-[0.729rem] font-normal"
        primary={true}
        rounded={true}
        onClick={handleVerifyClick}
      >
        Start
        <IcArrowUpFill />
      </Button>
    </section>
  );
};

export default KycVerificationBanner;
