import IcCross from "@/assets/ic_cross";
import { IcDropdownChecked } from "@/assets/menu";
import { ITransaciton } from "@/types/transactions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

interface ITransactionItemProps {
    item: ITransaciton;
    key: string;
    onClick: () => void;
    isSelected: boolean;
}

const TransactionItem = ({ item, key, onClick, isSelected }: ITransactionItemProps) => {
    // Parse the date with the correct format
    const parsedDate = dayjs(item.date, "D/M/YYYY, h:mm:ss a");

    return (
        <div className={`text-[14px] py-[14px] px-4 grid grid-cols-5 text-white border-b cursor-pointer border-monochrome-40 ${isSelected ? 'bg-green-10' : ''}`} key={key} onClick={onClick}>
            <span>{parsedDate.format("DD/MM/YYYY")}</span>
            <span>Token transfer</span>
            <span>{item.tokenSymbol}</span>
            <span>{parsedDate.format("hh:mm A")}</span>
            <span className={`text-end flex gap-2 justify-end items-center ${item.tokenAmount < 0 ? "text-red-100" : "text-green-500"}`}>
                {item.tokenAmount < 0 ? "-" : "+"}
                {(item.tokenAmount / 1e18).toFixed(4)}
                {item.tokenAmount < 0 ? <IcCross className="!text-red-500" /> : <IcDropdownChecked className={'h-[16px] w-[17px]'} />}
            </span>
        </div>
    )
}

export default TransactionItem;
