import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import Loader from "@components/Loader";
import { fetchTransactions } from "@services/api";
import TransactionItem from "@/components/Page/Transactions/TransactionItem";
import Dropdown from "@/components/UI/Dropdown/Dropdown";
import Button from "@/components/UI/Button";
import IcFilter from "@/assets/ic_filter";
import { IcNextPage, IcPreviousPage, IcSearch } from "@/assets";
import { ITransaciton } from "@/types/transactions";

const sortingOptions = [
  {
    value: "newest",
    label: "Newest",
  },
  {
    value: "oldest",
    label: "Oldest",
  },
]
const perPageItems = [10, 25, 50, 100];

interface ITransactionsProps {
  selectedTransaction: ITransaciton | null
  setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>
}
const Transactions = ({ selectedTransaction, setSelectedTransaction }: ITransactionsProps) => {
  const { investorId } = useAuth();

  const [perPageCount, setPerPageCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortOrder, setSortOrder] = useState<string>("newest");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { data: transactions = [], isLoading: isLoadingTransactions, error: transactionsError } = useQuery({
    queryKey: ["getTransactions", investorId],
    queryFn: () => fetchTransactions(investorId),
    enabled: !!investorId,
    select: (data) => data.data ?? []
  });

  const REWARD_FROM_ADDRESS = "0xa7d36B87D814257ADCa79cFe521f2a3fdcDdfDDa".toLowerCase();
  const PENOMO_TOKEN_SYMBOL = "PENOMO";

  const getTransactionType = (transaction: any) => {
    const { from, tokenSymbol } = transaction;
    if (tokenSymbol === PENOMO_TOKEN_SYMBOL && from.toLowerCase() === REWARD_FROM_ADDRESS) {
      return "reward transaction";
    }
    return transaction.transactionType;
  };

  const getCardClass = (transaction: any) => {
    const transactionType = getTransactionType(transaction);
    return transactionType === "reward transaction" ? "cards reward-transaction" : "cards";
  };

  const sortedTransactions = useMemo(() => {
    return transactions.sort((a: any, b: any) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
    });
  }, [transactions, sortOrder]);

  const totalPages = useMemo(() => {
    return Math.ceil(sortedTransactions.length / perPageCount);
  }, [sortedTransactions.length, perPageCount]);

  const displayedTransactions = useMemo(() => {
    const startIndex = (currentPage - 1) * perPageCount;
    return sortedTransactions.slice(startIndex, startIndex + perPageCount);
  }, [sortedTransactions, currentPage, perPageCount]);

  const filteredTransactions = useMemo(() => {
    const searchText = searchQuery.toLowerCase();
    return displayedTransactions.filter((transaction: any) => {
      return (
        transaction.date?.toLowerCase().includes(searchText) ||
        transaction.transactionType?.toLowerCase().includes(searchText) ||
        transaction.tokenSymbol?.toLowerCase().includes(searchText) ||
        transaction.time?.toLowerCase().includes(searchText) ||
        transaction.amount?.toString().toLowerCase().includes(searchText)
      );
    });
  }, [displayedTransactions, searchQuery]);

  if (isLoadingTransactions) {
    return <Loader isLoading={true} />;
  }

  if (transactionsError) {
    return <div>Error loading transactions.</div>;
  }

  return (
    <div className="transactions-page">
      <div className="mb-4 flex flex-col sm:flex-row items-center justify-center lg:justify-start gap-4">
        <div className="relative w-full sm:w-[100px] md:w-[200px]">
          <input type="text"
            placeholder="Search transactions..."
            className="!w-full !sm:w-[100px] !md:w-[200px] !h-[40px] !text-[12px] !m-0 !bg-monochrome-100 !rounded-lg placeholder-[#9E9E9E]"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} />
          <div className="absolute top-[13px] right-3 text-monochrome-20">
            <IcSearch />
          </div>
        </div>

        <div className="flex gap-4">
          <Dropdown
            menuBoxMarginTop={5}
            className={'w-[200px] h-[40px] text-[0.688rem] !important rounded placeholder-[red]'}
            options={sortingOptions} 
            onChange={(selectedValue: any) => {
              setSortOrder(selectedValue.value);
              setCurrentPage(1);
            }}
            value={sortOrder}
            />
          <Button primary rounded classNames="!px-4 w-[150px] h-[40px] !bg-monochrome-100 border-none !text-monochrome-20 !text-[0.688rem]" >
            More Filters
            <IcFilter />
          </Button>
        </div>
      </div>

      <div className="min-h-[250px] lg:h-[80vh] overflow-x-auto sm:overflow-hidden rounded-lg bg-monochrome-100">
        <div className="relative flex flex-col min-h-[250px] lg:h-full w-[200%] sm:w-full">
          <div className="text-[0.688rem] py-[10px] px-4 grid grid-cols-5 text-monochrome-20 border-b border-monochrome-40 text-monochrome-20">
            <span>Date</span>
            <span>Type</span>
            <span>Symbols</span>
            <span>Time</span>
            <span className="text-end">Amount</span>
          </div>

          <div className="overflow-y-auto grow">
            {filteredTransactions && filteredTransactions.length > 0 ? (
              filteredTransactions.map((transaction: any, index: number) => (
                <TransactionItem key={'transaction-' + index} item={transaction} onClick={() => setSelectedTransaction(transaction)} isSelected={selectedTransaction?.hash === transaction.hash} />
              ))
            ) : (
              <div className="center min-h-40">No transactions available.</div>
            )}
          </div>

          <div className="w-full flex justify-between py-[14px] text-[14px] px-4">
            <div className="flex gap-3 items-center">
              <ul className="flex">
                {perPageItems.map((item: number, index: number) => (
                  <li key={'page-items-' + index} onClick={() => {
                    setPerPageCount(item);
                    setCurrentPage(1); // Reset current page on per page count change
                  }} className={`py-2 px-3 border-2 border-monochrome-20  first:rounded-l-lg last:rounded-r-lg  cursor-pointer bg-monochrome-60 text-monochrome-20 ${item == perPageCount ? '!border-green-500 !bg-green-10 !text-green-500 ' : ''}`}>{item}</li>
                ))}
              </ul>
              <span className="text-monochrome-20">Items per page</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-monochrome-20">Page</span>
              <input className="!bg-monochrome-60 !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto]" type="text" value={currentPage} min={1} onChange={(e) => setCurrentPage(Number(e.target.value))} />
              <span className="text-monochrome-20">of {totalPages}</span>
              <div className="grid grid-cols-2 border-2 border-monochrome-20 divide-monochrome-20 divide-x rounded-lg items-center">
                <button className="p-2 bg-monochrome-60 rounded-l-lg" onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                  disabled={currentPage === 1}><IcPreviousPage /></button>
                <button className="p-2 bg-monochrome-60 rounded-r-lg" onClick={() =>
                  setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)
                }
                  disabled={currentPage === totalPages}><IcNextPage /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
