import React from "react";

interface PenomoPreSaleCounterProps {
    seconds: number;
}
type AppState = {
    time: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    };
    seconds: number;
}
class PenomoPreSaleCounter extends React.Component<PenomoPreSaleCounterProps, AppState> {
    timer: any;
    constructor(props: PenomoPreSaleCounterProps) {
        console.log('I am called');

        super(props);
        this.state = {
            time: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            seconds: props.seconds
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(seconds: number) {

        var days = Math.floor(seconds / (3600 * 24));
        var hours = Math.floor(seconds % (3600 * 24) / 3600);
        var minutes = Math.floor(seconds % 3600 / 60);
        var sec = Math.floor(seconds % 60);

        let obj = {
            days,
            hours,
            minutes,
            seconds: sec
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.startTimer();
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }

    render() {
        if (this.state == null || this.state.time == null) {
            return (<></>)
        }
        return (
            <div className="sm:col-span-2 grid grid-cols-4 gap-4 text-center sm:ml-8 text-monochrome-20">
                <div className="border border-monochrome-40 py-2 px-3 xl:px-0 rounded">
                    <span className="text-white text-center font-bold">{this.state.time.days}</span>
                    <p className="text-center text-sm mt-1">Days</p>
                </div>
                <div className="border border-monochrome-40 py-2 px-3 xl:px-0 rounded">
                    <span className="text-white text-center font-bold">{this.state.time.hours}</span>
                    <p className="text-center text-sm mt-1">Hours</p>
                </div>
                <div className="border border-monochrome-40 py-2 px-3 xl:px-0 rounded]">
                    <span className="text-white text-center font-bold">{this.state.time.minutes}</span>
                    <p className="text-center text-sm mt-1">Minutes</p>
                </div>
                <div className="border border-monochrome-40 py-2 px-3 xl:px-0 rounded">
                    <span className="text-white text-center font-bold">{this.state.time.seconds}</span>
                    <p className="text-center text-sm mt-1">Seconds</p>
                </div>
            </div>
        );
    }
}
export default PenomoPreSaleCounter;