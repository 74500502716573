import { useEffect, useState } from "react";
import { IUser } from "@/types";
import { IcArrowNext, IcArrowUp, IcJoinPenomoPreSale, IcPenomoWhitelistJoined } from "@/assets";
import Button from "./UI/Button";
import JoinPenomoPreSaleModal from "./Page/Dashboard/Dialogs/ModalJoinPenomoPreSale";
import PenomoPreSaleCounter from "./Page/Dashboard/PenomoPreSaleCounter";
import dayjs from "dayjs";

interface PenomoPreSaleProps {
  isVerified: boolean;
  userData: IUser;
}
const PenomoPreSale = ({ isVerified, userData }: PenomoPreSaleProps) => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false);
  const [timerSecound, setTimerSecound] = useState<number>(0);
  const [isSaleEnded, setIsSaleEnded] = useState<boolean>(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (isRefresh: boolean = false) => {
    setIsWhitelisted(isRefresh);
    setShowModal(false);
  };

  useEffect(() => {
    if (userData && userData.walletDetails) {
      setIsWhitelisted(userData.walletDetails.isWalletWhitelisted);
      /**
       * The code is for testing but when we have data we have to set the stats as per backend
       * Either we will get end date so we have to set the setTimerSecound
       * and if the Sale is ended then we have to setup the setIsSaleEnded to true
       */
      // setTimeout(() => {
        // setIsSaleEnded(true);
        // setTimerSecound(dayjs("2024-07-23").diff(dayjs(), "second"));
      // }, 5000);
    }
  }, [userData]);

  if (!isVerified) {
    return (<div className="bg-monochrome-100 px-4 py-4 flex rounded-lg">
      <div className="flex grow items-center">
        <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-monochrome-20" />
        <div className="flex flex-column ml-4 text-monochrome-20">
          <h4 className="font-medium">
            Join PNMO presale
          </h4>
          <p className="mt-2 font-normal">
            Please complete your KYC first.
          </p>
        </div>
      </div>
      <Button disabled transparent={true}>
        <IcArrowNext />
      </Button>
    </div>);
  }


  if (timerSecound > 0) {
    return (
      <>
         <div className={` flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
          <div className="grid sm:grid-cols-3 gap-4 sm:gap-0 items-center text-monochrome-20 w-full">
            <div className="flex items-center gap-4">
              <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
              <h3 className="font-medium text-white">Presale starts in</h3>
            </div>
            <PenomoPreSaleCounter seconds={timerSecound} />
          </div>
        </div>
      </>
    );
  }

  if (isSaleEnded) {
    return (
      <>
        <div className={`flex flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 rounded-lg items-center`}>
          <div className="flex items-center w-full">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
            <div className="flex ml-4 text-monochrome-20 w-full items-center justify-between">
              <h3 className="font-medium text-white">Get PNMO</h3>
              <Button rounded primary>
                Purchase PNMO <IcArrowUp />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (isWhitelisted) {
    return (
      <>
       <div className={` flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
          <div className="flex grow items-center">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
            <div className="flex flex-column ml-4 text-monochrome-20">
              <p className="font-normal text-sm flex gap-x-2 items-center">
                You’re already in the PNMO whitelist <IcPenomoWhitelistJoined className="text-green-500" />
              </p>
              <h3 className="font-medium text-white mt-2">We will notify you once the PNMO presale starts</h3>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={` flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
        <div className="flex grow items-center">
          <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
          <div className="flex flex-column ml-4 text-monochrome-20">
            <h3 className="font-medium text-white">Join PNMO presale</h3>
          </div>
        </div>
        <Button transparent={true} onClick={handleOpenModal}>
          <IcArrowNext />
        </Button>
      </div>
      {userData && showModal && <JoinPenomoPreSaleModal handleClose={handleCloseModal} user={userData} />
      }
    </>
  );
};

export default PenomoPreSale;
