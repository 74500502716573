import { useQuery } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import Loader from "./Loader";
import { fetchUser } from "@services/api";
import { Modal } from "flowbite-react";
import Button from "./UI/Button";

const TopUpWallet = ({ closeModal }: { closeModal: () => void }) => {
  const { userId } = useAuth();

  const { data: userData, isLoading: isLoadingUser } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });
  
  return (
    <Modal show={true} onClose={closeModal} position={"center"}>
      <Modal.Header data-testid="top-up-wallet-modal-1" className="p-6 bg-monochrome-100 text-white"><h3 className="text-white">Top Up Wallet</h3></Modal.Header>
      <Modal.Body className="p-6 bg-monochrome-100 text-white flex flex-col gap-3">
        <p>Send funds to the following address to top up your wallet:</p>
        <div>
          <Loader isLoading={isLoadingUser} />
          <strong>{userData?.walletDetails?.web3authWalletAddress}</strong>
        </div>
      </Modal.Body>
      <Modal.Footer className="p-6 bg-monochrome-100 text-white flex justify-center">
        <Button rounded primary onClick={closeModal}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TopUpWallet;
