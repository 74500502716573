
const IcPeaqReward = (props: any) => {
    return (
        <div {...props}>
            <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0416 13C14.5443 13 14.0674 13.1975 13.7158 13.5492C13.3642 13.9008 13.1666 14.3777 13.1666 14.875V16.3662C13.1666 17.0162 13.5041 17.6188 14.0554 17.96L18.8879 20.9475C18.1528 21.3435 17.571 21.9736 17.2346 22.7377C16.8981 23.5019 16.8263 24.3565 17.0305 25.166C17.2348 25.9756 17.7033 26.6939 18.362 27.207C19.0206 27.7201 19.8317 27.9988 20.6666 27.9988C21.5016 27.9988 22.3126 27.7201 22.9713 27.207C23.6299 26.6939 24.0985 25.9756 24.3027 25.166C24.5069 24.3565 24.4351 23.5019 24.0987 22.7377C23.7623 21.9736 23.1804 21.3435 22.4454 20.9475L27.2779 17.96C27.5495 17.792 27.7737 17.5575 27.9292 17.2785C28.0848 16.9996 28.1665 16.6856 28.1666 16.3662V14.875C28.1666 14.3777 27.9691 13.9008 27.6175 13.5492C27.2658 13.1975 26.7889 13 26.2916 13H15.0416ZM18.1666 19.0325V14.25H23.1666V19.0325L20.9954 20.375C20.8966 20.4361 20.7828 20.4684 20.6666 20.4684C20.5505 20.4684 20.4366 20.4361 20.3379 20.375L18.1666 19.0325ZM20.6666 21.75C21.3297 21.75 21.9656 22.0134 22.4344 22.4822C22.9032 22.9511 23.1666 23.587 23.1666 24.25C23.1666 24.913 22.9032 25.5489 22.4344 26.0178C21.9656 26.4866 21.3297 26.75 20.6666 26.75C20.0036 26.75 19.3677 26.4866 18.8989 26.0178C18.43 25.5489 18.1666 24.913 18.1666 24.25C18.1666 23.587 18.43 22.9511 18.8989 22.4822C19.3677 22.0134 20.0036 21.75 20.6666 21.75Z" fill="currentColor" />
            </svg>
        </div>
    )
}

export default IcPeaqReward