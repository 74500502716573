import { IcApy, IcTokenPrice, IcTotalVolume } from "@/assets";
import Button from "@/components/UI/Button";

const ProjectCardItem = ({ item, key , onClick}: any) => {
    return (
        <div className="bg-monochrome-100 p-2 rounded-lg" key={key}>
            <img src={item.image} className="w-full" />
            <div className="px-2 pb-1">
                <h6 className="pt-3 font-medium text-lg mb-5">{item.name}</h6>
                <Button primary rounded disabled classNames="w-full mb-2" onClick={onClick}>Stay Tuned</Button>
            </div>
        </div>
    )
}

export default ProjectCardItem
