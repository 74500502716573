// src/hooks/useWebSocket.ts

import { useEffect } from "react";
import useStore from "@store/useStore";
import { http } from "@services/http";
import { ENDPOINT } from "@/constants";

const useWebSocket = (userId: string, onMessageReceived?: (arg: any) => void) => {
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_CONNECTION_STAGING || ''
  useEffect(() => {
    let socket: WebSocket;
    const connect = () => {
      socket = new WebSocket(websocketUrl); // Adjust the URL if necessary

      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
          if (message.userId === userId) {
            const updatedUserData = await http.get(`${ENDPOINT.USER}/${userId}`);
            useStore.setState({ userData: updatedUserData.data });
            if (typeof onMessageReceived === "function") {
              onMessageReceived(updatedUserData.data);
            }
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error, event.data);
        }
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed, attempting to reconnect");
        setTimeout(connect, 5000); // Reconnect after 5 seconds
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        socket.close();
      };
    };

    connect();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [userId, onMessageReceived]);
};

export default useWebSocket;
