/**
 * Conditionally joining classNames together
 * @doc http://jedwatson.github.io/classnames
 * @returns string
 */
// eslint-disable-next-line
export function classNames(...args) {
  const hasOwn = {}.hasOwnProperty;
  const classes = [];

  for (let i = 0; i < arguments.length; i++) {
    // eslint-disable-next-line
    const arg = arguments[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === "string" || argType === "number") {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        // eslint-disable-next-line
        const inner = classNames.apply(null, arg);
        if (inner) {
          classes.push(inner);
        }
      }
    } else if (argType === "object") {
      if (arg.toString !== Object.prototype.toString && !arg.toString.toString().includes("[native code]")) {
        classes.push(arg.toString());
        continue;
      }

      for (const key in arg) {
        if (hasOwn.call(arg, key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(" ");
}

/* formatting numbers as currency in US dollars
 * @link https://stackoverflow.com/a/16233919/1164642
 */
export const USDFormatter = (number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  }).format(number);

/**
 * The `formatYYYYMMDD` function takes a date input, converts it to UTC time, and returns the date in
 * the format YYYY-MM-DD.
 * @param date - It looks like you are providing a function `formatYYYYMMDD` that takes a date as input
 * and returns the date formatted as "YYYY-MM-DD". The function seems to be converting the input date
 * to UTC format and then extracting the date part.
 * @returns The function `formatYYYYMMDD` takes a date as input, converts it to a new Date object,
 * adjusts for the timezone offset, and then returns the date in the format "YYYY-MM-DD".
 */
export const formatYYYYMMDD = (date) => {
  const _date = new Date(date);
  return new Date(_date.getTime() - _date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
};

export const formatNumber = (values) => {
  if (!values) return values;
  const parts = `${values}`.split(".");
  const value = parts[0].replace(/\D/g, "");
  const decimal = parts[1];
  let newValue = new Intl.NumberFormat("en-EN").format(value);

  // Prevent non-numeric decimal
  if (!isNaN(decimal)) {
    newValue = `${newValue}.${decimal}`;
  }

  // Prevent placing 0 when empty
  return value === "" && newValue === "0" ? "" : newValue;
};

// format currency input
export function currencyFormat() {
  document.querySelectorAll("input[data-format=currency]").forEach((element) => {
    element.addEventListener("input", function (e) {
      const val = e.target.value;
      formatNumber(val);
    });
  });
}
