
const FillStakingCampaign = () => {
    return (
        <svg stroke="currentColor" fill="currentColor" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.09446 5.05555C9.07706 5.05555 11.4945 4.25005 11.4945 3.25555C11.4945 2.26105 9.07706 1.45555 6.09446 1.45555C3.11186 1.45555 0.694458 2.26105 0.694458 3.25555C0.694458 4.25005 3.11186 5.05555 6.09446 5.05555ZM12.8445 7.75555C11.2929 7.75555 9.80497 8.37189 8.70788 9.46898C7.6108 10.5661 6.99446 12.054 6.99446 13.6056C6.99446 15.1571 7.6108 16.645 8.70788 17.7421C9.80497 18.8392 11.2929 19.4556 12.8445 19.4556C14.396 19.4556 15.8839 18.8392 16.981 17.7421C18.0781 16.645 18.6945 15.1571 18.6945 13.6056C18.6945 12.054 18.0781 10.5661 16.981 9.46898C15.8839 8.37189 14.396 7.75555 12.8445 7.75555Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.8445 16.7556C12.939 16.7556 13.0263 16.7106 13.2018 16.6224L14.6094 15.906C15.2331 15.5892 15.5445 15.4317 15.5445 15.1806V12.0306M12.8445 16.7556C12.75 16.7556 12.6627 16.7106 12.4872 16.6224L11.0796 15.906C10.4559 15.5892 10.1445 15.4317 10.1445 15.1806V12.0306M12.8445 16.7556V13.6056M15.5445 12.0306C15.5445 11.7795 15.2331 11.622 14.6094 11.3052L13.2018 10.5888C13.0263 10.5006 12.939 10.4556 12.8445 10.4556C12.75 10.4556 12.6627 10.5006 12.4872 10.5888L11.0796 11.3052C10.4559 11.6211 10.1445 11.7795 10.1445 12.0306M15.5445 12.0306C15.5445 12.2817 15.2331 12.4392 14.6094 12.756L13.2018 13.4724C13.0263 13.5606 12.939 13.6056 12.8445 13.6056M10.1445 12.0306C10.1445 12.2817 10.4559 12.4392 11.0796 12.756L12.4872 13.4724C12.6627 13.5606 12.75 13.6056 12.8445 13.6056M0.694458 3.25557V10.4943C0.694458 11.1279 1.75466 11.9253 4.41146 12.2556M0.790758 7.34877C1.87526 8.30457 3.80846 8.70957 5.87306 8.70957M11.4837 3.36447V5.17797" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default FillStakingCampaign