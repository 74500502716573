
let API_BASE_URL = process.env.REACT_APP_PENOMO_BACKEND_API;

export const ENDPOINT = {
  USER: `${API_BASE_URL}/api/users`,
  INVESTOR: `${API_BASE_URL}/api/investors`,
  TRANSACTIONS: `${API_BASE_URL}/api/transactions/investor`,
  KYC_SUBMIT: `${API_BASE_URL}/api/user/submit/kyc`,
  KYB_SUBMIT: `${API_BASE_URL}api/investor/kyb/submit`,
  WEB3_AUTH: `${API_BASE_URL}/api/investor/web3auth`,
  CONTACT_SUPPORT: `${API_BASE_URL}/api/users/contactSupport`,
  PROJECTS: `${API_BASE_URL}/api/projects/all`,
  PROJECTSBYID: `${API_BASE_URL}/api/projects/company`,
  NOTIFICATION: `${API_BASE_URL}/api/investors/statusMessages`,
  READ_NOTIFICATION: `${API_BASE_URL}/api/users/notifications/markRead`,
  DOCS: `${API_BASE_URL}/api/documents`,
} as const;

export const ROUTES = {
  APP_ROOT: "/",
  DASHBOARD: "/dashboard",
  MARKETPLACE: "/marketplace",
  MARKETPLACEDETAILS: "/marketplace/details",
  TRANSACTIONS: "/transactions",
  NOTIFICATIONS: "/notifications",
  CONTACTSUPPORT: '/contact-support',
  STAKINGCAMPAIGN: '/staking-campaign',
  TOKEN_SALE: "/tokensale",
  SETTINGS: "/settings",
  SUPPORT: "/support",
  LOGOUT: "/logout",
  PROFILE_SETUP: "/setupProfile",
} as const;
