// src/provider/AuthContext.js

import React, { createContext, useEffect, useMemo, useState } from "react";
import { web3auth } from "@services/Web3AuthService"; // Import your web3auth instance
import { IAuthContext } from "@/types";
import { getTokenExpiration, isTokenExpired } from "@/utils/tokenUtils";

export const AuthContext = createContext<IAuthContext>({
  authToken: null,
  web3AuthToken: null,
  provider: null,
  login: (token: string, web3Token: string | null, web3Provider: any | null) => { },
  logout: () => { },
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem("authToken") || null);
  const [web3AuthToken, setWeb3AuthToken] = useState<string | null>(localStorage.getItem("web3AuthToken") || null);
  const [provider, setProvider] = useState<any | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false); // Add initialized state
  const [loginTime, setLoginTime] = useState<number | null>(
    parseInt(localStorage.getItem("loginTime") || "0", 10)
  ); // Initialize with stored login time
  
  useEffect(() => {
    // Initialize web3auth provider on mount
    const initializeWeb3Auth = async () => {
      try {
        await web3auth.initModal(); // Initialize web3auth modal
        await web3auth.connect(); // Connect to web3auth provider

        setProvider(web3auth.provider as any);
        setInitialized(true); // Set initialized state to true
      } catch (error) {
        console.error("Web3Auth initialization error:", error);
      }
    };

    if (web3AuthToken && !initialized) {
      initializeWeb3Auth();
    }
  }, [web3AuthToken, initialized]); // Add initialized to dependency array

  useEffect(() => {
    if (authToken && isTokenExpired(authToken, loginTime)) {
      logout();
    }

    const intervalId = setInterval(() => {
      const storedAuthToken = localStorage.getItem("authToken");
      if (!storedAuthToken || isTokenExpired(storedAuthToken, loginTime)) {
        logout();
      }
    }, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [authToken, loginTime]);

  const login = (token: string, web3Token: string | null, web3Provider: any) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);

    if (web3Token) {
      localStorage.setItem("web3AuthToken", web3Token);
      setWeb3AuthToken(web3Token);
    }

    if (web3Provider) {
      setProvider(web3Provider);
    }

    const currentTime = Date.now();
    localStorage.setItem("loginTime", currentTime.toString());
    setLoginTime(currentTime);

    const expirationTime = getTokenExpiration(token);
    if (expirationTime) {
      const timeoutId = setTimeout(() => {
        logout();
      }, expirationTime - currentTime);

      return () => clearTimeout(timeoutId);
    }
  };

  const logout = () => {
    console.log("Session Timed Out");
    localStorage.clear();
    setAuthToken(null);
    setWeb3AuthToken(null);
    setProvider(null);
    setInitialized(false);
    setLoginTime(null); // Clear login time
    window.location.reload();
  };

  const contextValue = useMemo(() => ({ authToken, web3AuthToken, provider, login, logout }), [
    authToken,
    web3AuthToken,
    provider,
  ]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
