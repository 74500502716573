import { IcActiveNetwork, IcNotification } from '@/assets/menu';
import arrowLeft from '@/assets/arrowLeft.svg';
import { useLocation } from 'react-router-dom';
import Dropdown from './UI/Dropdown/Dropdown';
import useAuth from '@/hooks/useAuth';
import { classNames } from '@/utils';
import { useState } from 'react';
import { IcSearch } from '@/assets';
import { NavBarList } from '@/constants/NavMenu';
import { ROUTES } from '@/constants';
import "./Header.css"
import Notifications from './Notifications';
import Button from './UI/Button';

const networkOptions = [
    {
        value: '',
        label: 'Select Network',
        icon: ''
    }, {
        value: 'sepolia',
        label: 'Sepolia',
        icon: './assets/ic_ethereum.svg'
    }
]

const Header = () => {
    const { pathname } = useLocation();
    const { userId } = useAuth();

    const [selectedNetwork, setSelectedNetwork] = useState('ethereum')

    const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
    const isMarketplaceDetailsPage = pathname === ROUTES.MARKETPLACEDETAILS;
    if (userId && isSetupProfilePage) {
        return null;
    }
    return (
        <header className={classNames("mt-1 sticky-header-custom px-3 py-4 flex flex-row sm:items-center justify-between gap-4 sticky top-0 z-20 bg-monochrome-60", { hidden: !userId })}>
            <h1 className='mb-0'>
                {NavBarList.find((item: any) => { return item.link == pathname })?.title}
                {isMarketplaceDetailsPage && (<div className='flex items-center' style={{ gap: "16px" }} >
                    <Button rounded classNames='bg-monochrome-100 rounded-lg aspect-square !p-3' onClick={() => window.history.back()}>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.8855 3.94281L5.27606 9.55225L4.33325 8.60945L9.94272 3L10.8855 3.94281Z" fill="white" />
                            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M9.94272 14.219L4.33325 8.60955L5.27606 7.66675L10.8855 13.2762L9.94272 14.219Z" fill="white" />
                        </svg>
                    </Button>Go back
                </div>)}
            </h1>

            <div className='flex flex-row gap-x-2 items-center sm:gap-y-4'>
                {/* {
                    pathname == '/marketplace' && <div className="relative w-[180px]">
                        <input type="text" placeholder="Search token here..." className="w-full !pr-[35px] !text-[12px] !m-0 !bg-monochrome-100 !rounded-lg" />
                        <div className="absolute top-[13px] right-3 text-monochrome-20">
                            <IcSearch />
                        </div>
                    </div>
                } */}

                <div className='flex items-center gap-x-6 pr-4'>
                    {/* <Dropdown
                        icon={<IcActiveNetwork />}
                        menuBoxMarginTop={5}
                        value={selectedNetwork}
                        className={'w-full md:w-[200px] h-[38px] bg-erieblack rounded'}
                        onChange={(selectedValue: any) => {
                            setSelectedNetwork(selectedValue.value)
                        }}
                        options={networkOptions}
                    /> */}
                    <Notifications/>
                </div>
            </div>
        </header>
    )
}

export default Header