import { ROUTES } from '.'
import {
    IcDashboard, IcBuyPresaleToken,
    IcContactSupport,
    IcMarketplace,
    IcSetting,
    IcStakingCampain,
    IcTransactions,
    FillDashboard,
    FillMarketplace,
    FillStakingCampaign,
    FillTransaction,
    FillContactSupport,
    FillSetting
  } from '../assets/menu'

  /**
   * The list of menu items to be shown in the sidebar
   */
export const NavBarList = [
    {
      title: 'Dashboard',
      icon: <IcDashboard />,
      iconWhenSelected: <FillDashboard />,
      link: ROUTES.DASHBOARD
    },
    {
      title: 'Invest',
      icon: <IcMarketplace />,
      iconWhenSelected: <FillMarketplace />,
      link: ROUTES.MARKETPLACE
    },
    {
      title: 'Campaigns',
      icon: <IcStakingCampain/>,
      iconWhenSelected: <FillStakingCampaign />,
      link: ROUTES.STAKINGCAMPAIGN
    },
    {
      title: 'Transactions',
      icon: <IcTransactions />,
      iconWhenSelected: <FillTransaction />,
      link: ROUTES.TRANSACTIONS
    },
    // {
    //   title: 'Buy Presale Token',
    //   icon: <IcBuyPresaleToken/>,
    //   link: '/buy-presale-token'
    // }, 
    {
      title: 'Contact Support',
      icon: <IcContactSupport />,
      iconWhenSelected: <FillContactSupport />,
      link: ROUTES.CONTACTSUPPORT
    }, {
      title: 'Settings',
      icon: <IcSetting />,
      iconWhenSelected: <FillSetting />,
      link: ROUTES.SETTINGS
    }
  ]