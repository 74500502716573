import React from "react";
import Select from "react-select";
import { DropdownProps, Option } from "./DropdownProps";
import { IcDropdownChecked } from "@/assets/menu";
import {components} from 'react-select';
const { Control } = components;

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  placeholder,
  icon,
  value,
  disabled,
  className,
  height,
  menuBoxMarginTop = 25,
  isSearchable = false,
}) => {
  const handleChange = (selectedOption: Option | null) => {
    onChange(selectedOption);
  };

  return (
    <Select
      options={options}
      className={className}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: "none",
          boxShadow: "none",
          borderColor: "transparent",
          height: height,
          color: "white",
          fontSize: "14px",
          background: '#1A1C22',
          borderRadius: "6px",
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: menuBoxMarginTop + "px",
          backgroundColor: "#1A1C22",
          borderRadius: "10px",
          borderColor: "transparent",
          color: "black",
          padding: "0",
        }),
      }}
      value={options?.find((option) => option.value === value)}
      components={{
        IndicatorSeparator: () => null,
        Control: ({children, ...props}: any)=>{
          const selected = props.getValue()[0];
            return (<Control className="flex items-center gap-2 h-full w-full px-3 text-sm select-dropdown" {...props}>
              {icon}
              {children}
            </Control>)
        },
        Option: ({innerProps, label, data, isSelected}: any) =>{
          return (<div className={`flex items-center justify-between gap-2 h-10 px-2 text-white cursor-pointer first:border-none border-t border-[#383838] ${
            isSelected
                ? 'bg-[#383838]'
                : 'hover:bg-[#383838]'
        }`} {...innerProps}>
           <div className="flex items-center gap-2">
             {
              data.icon && <img className="w-4 aspect-square" src={data.icon}></img>
            }
            <span className={`text-sm`}>{label}</span>
           </div>
            {isSelected && <IcDropdownChecked/>}
          </div>)
        }
      }}
      isSearchable={isSearchable}
      onChange={(val) => {
        handleChange(val);
      }}
      isDisabled={disabled}
    />
  );
};

export default Dropdown;