import { useRef, useState, useEffect } from "react";
import useClickAway from "../hooks/useClickAway";
import { IcNotification, IcPercentage, IcRedirect } from "@/assets/menu";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "./UI/Button";
import IcCross from "@/assets/ic_cross";
import { fetchAllNotifications, readNotification } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import Loader from "./Loader";
import useStore from "@/store/useStore";
import dayjs from "dayjs";

const Notifications = () => {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [toggleNotifications, setToggleNotifications] = useState(false);
  const [crossIcon, setShowCrossIcon] = useState(-1);
  const notificationsRef = useRef(null);
  const navigate = useNavigate()
  const location = useLocation()

  const { userData } = useStore();
  const id = userData?._id || userData?.companyId

  const { data: notification = [], refetch } = useQuery({
    queryKey: ["getNotifications", id],
    queryFn: () => fetchAllNotifications(id),
    enabled: (userData && !!id),
    select: (data) => data.data
  });

  const mostRecent = [...notification].slice(0, 3).map((item: any) => ({
    ...item,
    icon: "bi bi-coin",
    color: "#1DD05D"
  }))

  useClickAway(notificationsRef, () => {
    setToggleNotifications(false);
  });

  useEffect(() => {
    setToggleNotifications(false);
  }, [location.pathname]);

  const handleDeleteClick = async (notificationId: any, type: any) => {
    setDeleteLoading(true)
    await readNotification(notificationId.toString());
    await refetch();
    setDeleteLoading(false)
    // let url;

    // if (type === "user") {
    //   url = `${process.env.REACT_APP_PENOMO_BACKEND_API}/api/user/${userId}/notification/${notificationId}`;
    // } else if (type === "investor") {
    //   url = `${process.env.REACT_APP_PENOMO_BACKEND_API}/api/investor/${investorId}/notification/${notificationId}`;
    // }

    // try {
    //   await axios.delete(url || "");
    // } catch (error) {
    //   console.error("Error deleting notification:", error);
    // }
  };

  const showCrossIcon = (index: any) => {
    setShowCrossIcon(index)
  }

  const hideCrossIcon = () => {
    setShowCrossIcon(-1)
  }

  const handleNagivation = (path: string) => {
    navigate(path)
  }
  const NotificationComponent = ({ notificationList }: any) => {
    return (
      <div className="w-[320px] sm:w-[450px] border border-monochrome-40 rounded-lg shadow-lg !p-0 text-white bg-monochrome-100 !divide-y !divide-monochrome-40">
        {notificationList.map((item: any, index: any) => (
          <div className="flex justify-between items-center p-4 hover:bg-monochrome-60" key={index} onMouseEnter={() => showCrossIcon(index)} onMouseLeave={() => hideCrossIcon()}>
            <div className="flex justify-between gap-2 text-sm">
              <IcPercentage />
              <div style={{ maxWidth: "380px" }}>
                <p className="mb-3 text-sm leading-auto">
                  {item.messages.length > 60 ? item.messages.substring(0, 60) + ". . ." : item.messages}{" "}
                  <span className="text-monochrome-20">{dayjs(item.createdAt).format("DD/MM/YYYY")}</span>
                </p>
                {(item.status === "Complete KYC" || item.status === "Complete KYB") && (<Button secondary rounded classNames='bg-monochrome-60 border border-monochrome-40' onClick={()=>{
                  handleNagivation('/settings')
                }}>
                  <p className="text-sm leading-auto">{item.status}</p> <IcRedirect />
                </Button>)}
              </div>
            </div>
            {crossIcon === index && (<IcCross style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(item._id, "user")} />)}
          </div>
        ))}

        {/* <div className="flex justify-between items-center p-4 hover:bg-monochrome-60">
          <div className="flex justify-between gap-2 text-sm">
            <IcPercentage />
            <div>
              <p className="mb-3 text-sm leading-auto">
                It is Important to complete your KYC for participating in the
                presale of Penomo Tokens.{" "}
                <span className="text-monochrome-20">15 Jul</span>
              </p>
              <Button secondary rounded classNames='bg-monochrome-60 border border-monochrome-40'>
                <p className="text-sm leading-auto"> Verify KYC</p> <IcRedirect />
              </Button>
            </div>
          </div>
          <IcCross />
        </div> */}

        <div className="text-center w-full" >
          <button className="decoration-none py-3 text-monochrome-20" onClick={() => navigate("/notifications")}>
            See all notifications
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {deleteLoading && <div className="min-h-[40px] loader-center"><Loader isLoading={true} svgStyle={{ width: "2.5rem", height: "2.5rem" }} /></div>}
      <div className="relative">
        <button
          type="button"
          className="relative inline-flex items-center text-sm font-medium text-center rounded-lg"
          onClick={() => setToggleNotifications(!toggleNotifications)}
        >
          <IcNotification/>
          <span className="sr-only">Notifications</span>
          {/* <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold border-2 rounded-full top-1 right-1 bg-green-600 text-white">
          {notifications.length}
        </div> */}
        </button>

        {toggleNotifications && (
          <div
            ref={notificationsRef}
            className="absolute right-0 mt-2 w-96 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50"
            style={{ width: "fit-content", backgroundColor: "#1e1e1e" }}
          >
            <div>
              <NotificationComponent notificationList={mostRecent} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
