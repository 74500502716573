import './marketplaceContent.css';
import iconWallet from '../../../../../assets/iconWallet.png'
import iconWallet3 from '../../../../../assets/iconWallet3.png'
import carbon_energy from '../../../../../assets/carbon_energy-renewable.png'
import twemoji_flag from '../../../../../assets/twemoji_flag-germany.png'

interface MarketplaceContentProps {
    overviewRef: any
    updatesRef: any
    documentsRef: any
    financialsRef: any
    detailsRef: any
    companyData: any
}

const MarketplaceContent = (props: MarketplaceContentProps) => {
    const { name, tokenPrice, irr } = { ...props.companyData?.data[0] }

    const { overviewRef, updatesRef, documentsRef, financialsRef, detailsRef } = props
    return (
        <div className="text-white">
            <div className="flex marketplace-details-container" style={{ gap: "30px", flexDirection: "row" }}>
                <div className="col-md-8" style={{ width: "100%" }}>
                    <div className="marketplace-card custom-card mb-3" ref={overviewRef}>
                        <div className="card-body">
                            <h5 className="card-title">Overviews</h5>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Capital Needed</span>
                                <span>1,300,000 USD</span>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Revenue Generating Assets</span>
                                <span>Battery Storage</span>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Total Financed</span>
                                <span>129,252 USD</span>
                            </div>
                        </div>
                    </div>

                    <div className="marketplace-card custom-card" ref={detailsRef}>
                        <div className="card-body">
                            <h5 className="card-title">Details</h5>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Power Capacity</span>
                                <span>160,000 kW</span>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Type of Revenue and Project Revenue</span>
                                <div style={{ width: "55%", padding: "0" }}>
                                    <div className='flex justify-between' style={{ marginBottom: "25px" }}>
                                        <div>Energy Sales PPA</div>
                                        <div className='text-gray'>1,300,000 USD per month</div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div>Carbon Credits</div>
                                        <div className='text-gray'>1,500,000 USD per month</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Physical Location</span>
                                <div className='flex items-center' style={{ gap: "10px" }}>
                                    <img style={{ width: "20px", height: "20px" }} src={twemoji_flag} alt='twemoji_flag' />
                                    <span>German</span>
                                </div>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>SPV Location</span>
                                <div className='flex items-center' style={{ gap: "10px" }}>
                                    <img style={{ width: "20px", height: "20px" }} src={twemoji_flag} alt='twemoji_flag' />
                                    <span>German</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="marketplace-card custom-card mb-3" ref={financialsRef}>
                        <div className="card-body">
                            <h5 className="card-title">Financials</h5>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Capital Needed</span>
                                <span>1,300,000 USD</span>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Total Financed</span>
                                <span>129,252 USD</span>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Percentage Funded</span>
                                <span>9,8 %</span>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Total Project Revenue</span>
                                <span>2,800,000 USD per month</span>
                            </div>
                        </div>
                    </div>

                    <div className="marketplace-card custom-card" ref={documentsRef}>
                        <div className="card-body">
                            <h5 className="card-title">Documents</h5>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Additional Links</span>
                                <span>https://adtioasd.sdhwsx</span>
                            </div>
                            <div className="flex details-wraper">
                                <span className='text-gray'>Attached Files</span>
                                <div style={{ width: "55%", padding: "0" }}>
                                    <div className='flex justify-between' style={{ marginBottom: "25px" }}>
                                        <div className='text-gray'>ID_Document.pdf</div>
                                    </div>
                                    <div className='flex justify-between' style={{ marginBottom: "25px" }}>
                                        <div className='text-gray'>Master_plan.pdf</div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='text-gray'>Tax.jpg</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="marketplace-card custom-card mb-3" ref={updatesRef}>
                        <div className="card-body">
                            <h5 className="card-title">Updates</h5>
                            <div className="flex details-wraper">
                                <span className='text-gray'>No updates</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 invest-now-section" style={{ width: "600px" }}>
                    <div className="marketplace-card custom-card invest-card">
                        <div className="card-body" style={{ padding: "0" }}>
                            <div className='flex' style={{ gap: "20px" }}>
                                <img style={{ width: "32px", height: "32px" }} src={carbon_energy} alt='' />
                                <div>
                                    <h2 style={{ marginBottom: "5px" }}>{name}</h2>
                                    <p style={{ marginBottom: "30px" }}>Battery Storage</p>
                                </div>
                            </div>
                            <div className='flex justify-between' style={{ marginBottom: "26px" }}>
                                <div className='flex items-center' style={{ gap: "10px" }}>
                                    <img style={{ width: "26px", height: "26px" }} src={iconWallet3} alt='' />
                                    <p>Token Price: ${tokenPrice}</p>
                                </div>
                                <div className='flex items-center' style={{ gap: "10px" }}>
                                    <img style={{ width: "26px", height: "26px" }} src={iconWallet} alt='' />
                                    <p>APY: {irr}%</p>
                                </div>
                            </div>
                            <button className="btn btn-success" style={{ width: "100%" }}>Invest Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketplaceContent;
