import Button from "../../UI/Button";
import Loader from "../../Loader";
import RawTokenListItem from "./RawTokenListItem";

const dummyData = [
  {
    icon: "./assets/dummy_project.png",
    name: "Sun Estate Token",
    contractTerm: "12 months",
    projectSize: 425300,
  },
  {
    icon: "./assets/dummy_project.png",
    name: "Solar Investment",
    contractTerm: "6 months",
    projectSize: 334300,
  },
  {
    icon: "./assets/dummy_project.png",
    name: "Commodities Token",
    contractTerm: "12 months",
    projectSize: 462100,
  },
  {
    icon: "./assets/dummy_project.png",
    name: "Solar Panel INA",
    contractTerm: "12 months",
    projectSize: 128300,
  },
  {
    icon: "./assets/dummy_project.png",
    name: "Bio Solar Token",
    contractTerm: "12 months",
    projectSize: 128300,
  },
];
const RawTokenList = () => {
  return (
    <div className="bg-monochrome-100 p-4 flex flex-column rounded-lg">
      <div className="flex justify-between">
        <h5 className="font-medium ">RWA Tokens</h5>
        <Button classNames="text-green-500 justify-end pr-0 text-[0.75rem] font-normal">View All</Button>
      </div>
      <div className="overflow-x-auto sm:overflow-hidden">
        <div className="mt-4 w-[150%] sm:w-full">
          <div className="grid grid-cols-4 text-monochrome-20 pb-6 pt-2 border-b border-monochrome-40">
            <span className="text-[0.688rem] text-[#9E9E9E]">Name</span>
            <span className=" text-end text-[0.688rem] text-[#9E9E9E]">APY</span>
            <span className=" text-end text-[0.688rem] text-[#9E9E9E]">Amount</span>
            <span className=" text-end text-[0.688rem] text-[#9E9E9E]">Value</span>
          </div>
          <div className="center min-h-40 text-[0.75rem]">RWA tokens upcoming, stay tuned.</div>
          {/* {
                    isLoading && <div className="center min-h-20">
                        <Loader isLoading={true} />
                    </div>
                } */}
          {/* {
                    dummyData.slice(0, 5).map((data, index) => <RawTokenListItem key={index} item={data} />)
                } */}
          {/* Uncomment the code when integrate the api */}
          {/* {
                    !isLoading && token.length == 0 && <div className="center min-h-40">
                        No RAW token available.
                    </div>
                } */}
        </div>
      </div>
    </div>
  );
};

export default RawTokenList;
