import Loader from '@/components/Loader';
import { ENDPOINT } from '@/constants';
import { updateUser } from '@/services/api';
import { http } from '@/services/http';
import useStore from '@/store/useStore';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Switch from "react-switch";
const NotificationSettings = () => {


    const { userData, setUser } = useStore();
    const [isEmailNotificaiotnEnabled, setIsEmailNotificaiotnEnabled] = useState(userData?.emailNotifications ?? false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleToggleChange = async () => {
        try {
            setIsSubmitting(true);
            const response = await updateUser(userData._id,{ emailNotifications: !isEmailNotificaiotnEnabled });
            if(response.data){
                setUser({
                    ...userData,
                    emailNotifications: !isEmailNotificaiotnEnabled
                }); 
            }
            setIsSubmitting(false);
            setIsEmailNotificaiotnEnabled(!isEmailNotificaiotnEnabled);
            toast.success("Email notification setting updated successfully!");
        } catch (error) {
            setIsSubmitting(false);
            console.error("Error updating email notification setting:", error);
            toast.error("Failed to update email notification setting.");
        }
    };
    return (
        <div className='flex flex-col gap-4'>
            <h3 className='text-monochrome-20'>Notifications</h3>
            <div className='flex flex-col md:flex-row bg-monochrome-100 p-4 rounded divide-y md:divide-y-0 md:divide-x divide-monochrome-40'>
                <div className='flex flex-col gap-2 md:w-6/12'>
                    <h3>Email Notifications</h3>
                    <p className='text-monochrome-20 text-sm'>Manage your preferences for receiving email notifications</p>
                </div>
                <div className='flex items-center gap-2 md:w-6/12 md:pl-6 mobile-space'>
                    <Switch
                        offColor='#282C35'
                        onColor='#00cc9c'
                        activeBoxShadow={''}
                        offHandleColor={'#9E9E9E'}
                        onHandleColor={'#fff'}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={18}
                        disabled={isSubmitting}
                        borderRadius={9}
                        className={`overflow-hidden border-2 rounded-full border-monochrome-40 ${isEmailNotificaiotnEnabled ? 'border-green-500' : ''}`}
                        onChange={handleToggleChange} checked={isEmailNotificaiotnEnabled}
                    />
                    Receive Email Notifications
                    {
                        isSubmitting && <Loader isLoading={true}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default NotificationSettings