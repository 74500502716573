import { IcArrowUpBuy, IcMergeCopy } from '@/assets'
import IcCross from '@/assets/ic_cross'
import { IcDropdownChecked } from '@/assets/menu'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import Web3 from 'web3';

// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

const TransactionDetailSideView = ({ transaction, onClose }: any) => {
  console.log(transaction);

  const showCopiedToast = (message: string) => {
    toast.success(message);
  }

  // Parse the date with the correct format
  const parsedDate = dayjs(transaction.date, "D/M/YYYY, h:mm:ss a");

  return (
    <div className='bg-monochrome-100 flex flex-col p-4 gap-8 absolute right-0 top-0 z-30 w-full h-screen lg:relative lg:w-1/5'>
      <div className='flex justify-between items-center'>
        <h3>Transaction Details</h3>
        <IcCross className={'p-1 bg-monochrome-60 border border-monochrome-40 p-2 rounded-lg cursor-pointer'} onClick={onClose}></IcCross>
      </div>
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-2 text-monochrome-20 text-sm'>
          <IcArrowUpBuy className={'p-2 bg-green-10 text-green-500 rounded-full'} />
          Buy
        </div>
        <h2>{Web3.utils.fromWei(transaction.tokenAmount.toString(), "ether")} USD</h2>
      </div>
      <div className='flex gap-2 flex-col text-sm'>
        <span className='text-monochrome-20'>Status</span>
        <div className='flex fap-2'>
          <IcDropdownChecked />Success
        </div>
      </div>
      <div className='flex gap-2 flex-col text-sm'>
        <span className='text-monochrome-20'>Time</span>
        <span>{parsedDate.format("hh:mm:ss, MMM DD, YYYY")}</span>
      </div>
      <div className='flex gap-2 flex-col text-sm'>
        <span className='text-monochrome-20'>Symbols</span>
        <span>{transaction.tokenSymbol}</span>
      </div>
      <div className='flex gap-2 flex-col text-sm'>
        <span className='text-monochrome-20'>Transaction hash</span>
        <div className='flex flex-row gap-2 justify-between items-center'>
          <p className='text-ellipsis overflow-hidden text-blue-100'>{transaction.hash}</p>
          <CopyToClipboard text={transaction.hash} onCopy={() => showCopiedToast('Transaction hash copied to clipboard!')}><IcMergeCopy className={'cursor-pointer'} /></CopyToClipboard>
        </div>
      </div>
      <div className='flex gap-2 flex-col text-sm'>
        <span className='text-monochrome-20'>From</span>
        <div className='flex flex-row gap-2 justify-between items-center'>
          <p className='text-ellipsis overflow-hidden text-blue-100'>{transaction.from}</p>
          <CopyToClipboard text={transaction.from} onCopy={() => showCopiedToast('From address copied to clipboard!')}><IcMergeCopy className={'cursor-pointer'} /></CopyToClipboard>
        </div>
      </div>
      <div className='flex gap-2 flex-col text-sm'>
        <span className='text-monochrome-20'>To</span>
        <div className='flex flex-row gap-2 justify-between items-center'>
          <p className='text-ellipsis overflow-hidden text-blue-100'>{transaction.to}</p>
          <CopyToClipboard text={transaction.to} onCopy={() => showCopiedToast('To address copied to clipboard!')}><IcMergeCopy className={'cursor-pointer'} /></CopyToClipboard>
        </div>
      </div>
      <div className='flex gap-2 flex-col text-sm'>
        <span className='text-monochrome-20'>Block Explorer</span>
        <div className='flex flex-row gap-2 justify-between items-center'>
          <a href={`${process.env.REACT_APP_ETHERSCAN_TRANSACTION_URL}${transaction.hash}`} target='_blank' rel="noreferrer" className='text-ellipsis overflow-hidden text-blue-100'>{`${process.env.REACT_APP_ETHERSCAN_TRANSACTION_URL}${transaction.hash}`}</a>
        </div>
      </div>
    </div>
  )
}

export default TransactionDetailSideView;
