const IcNotification = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7195 17.6024C8.77943 17.6024 6.83938 17.2943 4.99925 16.6782C4.29983 16.4367 3.76694 15.9455 3.5338 15.3043C3.29234 14.6632 3.3756 13.9554 3.75862 13.3226L4.71615 11.7323C4.91598 11.3992 5.09916 10.7331 5.09916 10.3418V7.93547C5.09916 4.83805 7.62206 2.31516 10.7195 2.31516C13.8169 2.31516 16.3398 4.83805 16.3398 7.93547V10.3418C16.3398 10.7248 16.523 11.3992 16.7228 11.7406L17.672 13.3226C18.03 13.9221 18.0967 14.6465 17.8552 15.3043C17.6137 15.9621 17.0892 16.4617 16.4314 16.6782C14.5996 17.2943 12.6595 17.6024 10.7195 17.6024ZM10.7195 3.56411C8.31315 3.56411 6.34812 5.52081 6.34812 7.93547V10.3418C6.34812 10.9496 6.09833 11.8489 5.79025 12.3734L4.83272 13.9638C4.64954 14.2718 4.59958 14.5966 4.70782 14.8713C4.80774 15.1544 5.05753 15.3709 5.39891 15.4875C8.87934 16.6532 12.5679 16.6532 16.0484 15.4875C16.3481 15.3876 16.5813 15.1628 16.6895 14.863C16.7977 14.5633 16.7728 14.2385 16.6062 13.9638L15.6487 12.3734C15.3323 11.8322 15.0908 10.9413 15.0908 10.3335V7.93547C15.0908 5.52081 13.1341 3.56411 10.7195 3.56411Z" fill="#9E9E9E" />
            <path d="M12.2683 3.78893C12.2101 3.78893 12.1518 3.7806 12.0935 3.76395C11.852 3.69734 11.6189 3.64738 11.3941 3.61407C10.6863 3.52248 10.0036 3.57244 9.36243 3.76395C9.12929 3.83889 8.8795 3.76395 8.7213 3.58909C8.5631 3.41424 8.51314 3.16445 8.60473 2.93964C8.94611 2.06537 9.77875 1.49084 10.728 1.49084C11.6772 1.49084 12.5098 2.05704 12.8512 2.93964C12.9345 3.16445 12.8928 3.41424 12.7346 3.58909C12.6097 3.72232 12.4349 3.78893 12.2683 3.78893Z" fill="#9E9E9E" />
            <path d="M10.7196 19.5008C9.89525 19.5008 9.09592 19.1678 8.51307 18.5849C7.93022 18.0021 7.59717 17.2027 7.59717 16.3784H8.84613C8.84613 16.8697 9.04596 17.3526 9.39567 17.7023C9.74538 18.052 10.2283 18.2519 10.7196 18.2519C11.752 18.2519 12.593 17.4109 12.593 16.3784H13.842C13.842 18.102 12.4431 19.5008 10.7196 19.5008Z" fill="#9E9E9E" />
            <circle cx="14.8661" cy="4.67153" r="2.0816" fill="#00CC9C" stroke="#1A1C22"  />
        </svg>

    )
}

export default IcNotification;
