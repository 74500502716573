import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useStore from "@store/useStore";
import { fetchAllProjects } from "@services/api";
import Loader from "@/components/Loader";

import "./Marketplace.css";
import ProjectCardItem from "@/components/Page/Marketplace/ProjectCardItem";
import ProjectView from "./Projects/ProjectView";
import { useNavigate } from "react-router-dom";


const Marketplace = () => {
  const navigate = useNavigate()
  const [selectedProject, setSelectedProject] = useState(null);
  const { investorData: investorDetails } = useStore();

  const projects = [
    {
      name: 'Wind Assets',
      image: '/assets/dummy/sample-1.jpg',
      id: 1
    },{
      name: 'Solar Assets',
      image: '/assets/dummy/sample-2.jpg',
      id: 2
    },{
      name: 'EV Charging Stations',
      image: '/assets/dummy/sample-3.jpg',
      id: 3
    }, {
      name: 'Battery Storages',
      image: '/assets/dummy/sample-4.jpg',
      id: 4
    }
  ]
  // const { data: projects = [], isLoading } = useQuery({
  //   queryKey: ["getProjects"],
  //   queryFn: fetchAllProjects,
  //   select: (data) => data.data ?? [],
  // });


  const handleProjectClick = (project: any) => {
    // navigate(`/marketplace/details?projectId=${project.companyId._id}`)
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  return (
    <>
      {/* {
        isLoading && <div className="w-full flex justify-center items-center h-20"><Loader isLoading={true} /></div>
      } */}

      <div className="w-full flex justify-center items-center"></div>
      {

        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
          {projects.map((project: any, index: number) => (
            <ProjectCardItem key={'project-' + index} item={project} onClick={() => handleProjectClick(project)} />
          ))}
        </div>

      }
      {selectedProject && <ProjectView project={selectedProject} close={handleCloseModal} />}
    </>
  );
};

export default Marketplace;
