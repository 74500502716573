import {jwtDecode} from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";

import { AuthContext } from "@/provider/AuthContext";
import { ENDPOINT, ROUTES } from "@constants";
import { web3auth } from "@services/Web3AuthService";
import { fetchUser } from "@services/api";
import { http } from "@services/http";
import useStore from "@store/useStore";
import GeneralInfoDialog from "./UI/GeneralInfoDialog";

interface Web3AuthResponse {
  authToken: string;
  investorId: string;
  userId: string;
}

const Login = () => {
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setUser } = useStore();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const web3AuthToken = localStorage.getItem("web3AuthToken");
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleWeb3AuthLogin = async () => {
    if (isInitializing) return;
    setIsInitializing(true);

    try {
      if (!initialized) {
        await web3auth.initModal();
        setInitialized(true);
      }

      await web3auth.connect();
      const userInfo = await web3auth.getUserInfo();
      const email = userInfo.email;

      if (!web3auth.provider) {
        toast.error("Web3Auth provider is not available! Try again.");
        throw new Error("Web3Auth provider is not available");
      }

      const web3 = new Web3(web3auth.provider as any);
      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) throw new Error("No account found");
      const publicAddress = accounts[0];

      const idToken = (await web3auth.authenticateUser()).idToken;

      try {
        const response = await http.post<Web3AuthResponse>(
          `${ENDPOINT.INVESTOR}/web3auth`,
          { email, publicAddress },
          {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          }
        );

        if (response?.status === 200) {
          const authToken = response.data?.data?.authToken;
          if (authToken) {
            login(authToken, idToken, web3auth.provider);
            localStorage.setItem("authToken", authToken);
            localStorage.setItem("web3AuthToken", idToken);

            const decoded: any = jwtDecode(authToken);
            const userId = decoded?.userId;
            const userData = await fetchUser(userId);
            if (userData.data) {
              setUser(userData.data);
              const isProfileExisting = userData.data.isProfileExisting;
              navigate(isProfileExisting ? ROUTES.DASHBOARD : ROUTES.PROFILE_SETUP);
            }
          }
        }
      } catch (error: any) {
        if (error.response && error.response.status === 409) {
          console.log("409 Conflict error detected:", error.response.data.message);
          setErrorMessage(error.response.data.message);
        } else {
          toast.error("Login failed. Please try again.");
        }
        console.error("Web3Auth login error:", error);
      }
    } catch (error) {
      console.error("Web3Auth login error:", error);
    } finally {
      setIsInitializing(false);
    }
  };

  return isLoggedIn ? (
    <h1 className="text-white">Welcome back!</h1>
  ) : (
    <>
       <button
        data-testid="login-register"
        onClick={handleWeb3AuthLogin}
        className="!m-0 font-normal btn-penomo-navbar rounded-[0.5rem] py-[0.625rem] px-[1.75rem] text-[0.938rem]"
      >
        Log in
      </button>
      {errorMessage && (
        <GeneralInfoDialog
          title="Login Error"
          noTitle="Ok"
          message={errorMessage}
          onButtonClick={() => {
            setErrorMessage(null);
            localStorage.clear();
            window.location.reload();
          }}
        />
      )}
    </>
  );
};

export default Login;