import { useRef } from 'react';
import './Tabs.css';
import MarketplaceContent from '../MarketplaceContent/MarketplaceContent';

interface InvestmentCard {
    companyData: any
}

const Tabs = ({ companyData }: InvestmentCard) => {
    const overviewRef = useRef(null);
    const detailsRef = useRef(null);
    const financialsRef = useRef(null);
    const documentsRef = useRef(null);
    const updatesRef = useRef(null);

    const handleScrollToSection = (ref: any) => {
        if (ref && ref.current) {
            const offsetTop = ref.current.offsetTop - (window.innerHeight / 2) + (ref.current.clientHeight / 2);
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="custom-wrapper">
            <div className="custom-tabs-panel">
                <input type="radio" id="custom-test1" name="custom-tab" defaultChecked />
                <input type="radio" id="custom-test2" name="custom-tab" />
                <input type="radio" id="custom-test3" name="custom-tab" />
                <input type="radio" id="custom-test4" name="custom-tab" />
                <input type="radio" id="custom-test5" name="custom-tab" />
                <div className="custom-tabs" style={{ width: "65%" }}>
                    <div className="custom-tab" onClick={() => handleScrollToSection(overviewRef)}>
                        <label htmlFor="custom-test1">
                            <div className="custom-content">
                                <div className="custom-text">Overview</div>
                            </div>
                        </label>
                    </div>
                    <div className="custom-tab" onClick={() => handleScrollToSection(detailsRef)}>
                        <label htmlFor="custom-test2">
                            <div className="custom-content">
                                <div className="custom-text">Details</div>
                            </div>
                        </label>
                    </div>
                    <div className="custom-tab" onClick={() => handleScrollToSection(financialsRef)}>
                        <label htmlFor="custom-test3">
                            <div className="custom-content">
                                <div className="custom-text">Financials</div>
                            </div>
                        </label>
                    </div>
                    <div className="custom-tab" onClick={() => handleScrollToSection(documentsRef)}>
                        <label htmlFor="custom-test4">
                            <div className="custom-content">
                                <div className="custom-text">Documents</div>
                            </div>
                        </label>
                    </div>
                    <div className="custom-tab" onClick={() => handleScrollToSection(updatesRef)}>
                        <label htmlFor="custom-test5">
                            <div className="custom-content">
                                <div className="custom-text">Updates</div>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="custom-separator"></div>
            </div>
            <div className="custom-panels p-4">
                <div className="custom-tab-panel" id="custom-panel1">
                    <MarketplaceContent
                        companyData={companyData}
                        overviewRef={overviewRef}
                        updatesRef={updatesRef}
                        documentsRef={documentsRef}
                        financialsRef={financialsRef}
                        detailsRef={detailsRef}
                    />
                </div>
            </div>
        </div>
    );
};

export default Tabs;
