import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import useStore from "@store/useStore";
import { fetchUser, fetchInvestor } from "@services/api";
import useWebSocket from "@/hooks/useWebSocket";
import Loader from "@components/Loader";
import BalanceCard from "@components/BalanceCard";
import PenomoRewards from "@components/PenomoRewards";
import KycVerificationBanner from "@/components/KycVerificationBanner";
import PenomoPreSale from "@/components/PenomoPreSaleBanner";
import PeaqRewardingCampaign from "@/components/Page/Dashboard/PeaqRewardBanner";
import { IUser } from "@/types";
import RecentTransactions from "@/components/Page/RecentTransactions/RecentTransactions";
import RawTokenList from "@/components/Page/RAWToken/RawTokenList";
import { ITransaciton } from "@/types/transactions";

interface ITransactionsProps {
  selectedTransaction: ITransaciton | null;
  setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
}

const DashboardPage = (props: ITransactionsProps) => {
  const { userId, investorId } = useAuth();
  const { setUser, setInvestor, userData: storeUserData } = useStore();
  const queryClient = useQueryClient();

  useWebSocket(userId, (userData: IUser) => {
    console.log("Updating using WebSockets: ",userData)
    console.log("Updated kyc Status from WebHook: ",userData.kycStatus)
    setUser(userData); // Update user data in the store
  });

  useEffect(() => {
    queryClient?.invalidateQueries({ queryKey: ["getUser", "getInvestor", "getTransactions"] });
  }, [userId, investorId, queryClient]);

  const { data: userData = null, isLoading: isLoadingUser = false } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
  }) || {};

  const { data: investorData = null } = useQuery({
    queryKey: ["getInvestor", investorId],
    queryFn: () => fetchInvestor(investorId),
    enabled: !!investorId,
  }) || {};

  useEffect(() => {
    if (userData) {
      setUser(userData.data);
    }
    if (investorData && investorData.data) {
      setInvestor(investorData.data);
    }
  }, [userData, investorData, setUser, setInvestor]);

  const getNotifications = () => {
    const notifications = [];

    if (userData?.statusUpdates) {
      const userNotifications = userData.statusUpdates.map((notification: any) => ({
        ...notification,
        type: "user",
      }));
      notifications.push(...userNotifications);
    }

    if (investorData?.statusUpdates) {
      const investorNotifications = investorData.statusUpdates.map((notification: any) => ({
        ...notification,
        type: "investor",
      }));
      notifications.push(...investorNotifications);
    }

    return notifications;
  };

  const notifications = getNotifications();
  const isVerified = storeUserData ? storeUserData.kycStatus === "Verified" : userData?.data?.kycStatus === "Verified";

  const REWARD_FROM_ADDRESS = "0xa7d36B87D814257ADCa79cFe521f2a3fdcDdfDDa".toLowerCase();
  const PENOMO_TOKEN_SYMBOL = "PNMO";

  const getTransactionType = (transaction: any) => {
    const { from, tokenSymbol } = transaction;
    if (tokenSymbol === PENOMO_TOKEN_SYMBOL && from.toLowerCase() === REWARD_FROM_ADDRESS) {
      return "reward transaction";
    }
    return transaction.transactionType;
  };

  const getCardClass = (transaction: any) => {
    const transactionType = getTransactionType(transaction);
    return transactionType === "reward transaction" ? "cards reward-transaction" : "cards";
  };

  return (
    <div className="p-4">
      {isLoadingUser ? (
        <div className="min-h-[40px] flex align-center justify-center">
          <Loader isLoading={true} />
        </div>
      ) : (
        <KycVerificationBanner isVerified={isVerified} />
      )}
      {userData && (
        <>
          <section className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4">
            <PenomoPreSale isVerified={isVerified} userData={userData.data} />
            <PeaqRewardingCampaign isVerified={isVerified} userData={userData.data} />
          </section>
        </>
      )}
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4">
        <BalanceCard />
        <PenomoRewards />
      </section>
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4">
        <RawTokenList />
        <RecentTransactions investorId={investorId} {...props} />
      </section>
    </div>
  );
};

export default DashboardPage;
