import React, { useState } from "react";
import Button from "../../UI/Button";
import { IcArrowNext, IcPeaqReward } from "@/assets";
import { IUser } from "@/types";
import ClaimReaqRewardModal from "./Dialogs/ModalClaimReaqReward";

interface ReaqRewardProps {
  isVerified: boolean;
  userData: IUser | null;
}

const PeaqRewardingCampaign = ({ isVerified, userData }: ReaqRewardProps) => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    // setShowModal(true)
  };
  const handleCloseModal = () => setShowModal(false);

  if (!isVerified)
    return (
      <div>
        <div className="bg-monochrome-100 px-4 py-4 flex rounded-lg">
          <div className="flex grow">
            <IcPeaqReward className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-monochrome-20" />
            <div className="flex flex-col justify-center ml-4 text-monochrome-20">
              <h4>Get PEAQ rewards</h4>
              <p className="mt-2 font-normal text-[0.688rem] text-monochrome-20">Stay Tuned.</p>
            </div>
          </div>
          <Button disabled transparent={true}>
            <IcArrowNext />
          </Button>
        </div>
      </div>
    );

  return (
    <>
      <div className="bg-monochrome-100 px-4 py-4 flex rounded-lg">
        <div className="flex grow items-center">
          <IcPeaqReward className="w-[40px] bg-monochrome-60 rounded-[8px] text-green-500" />
          <div className="flex flex-col justify-center ml-4">
            <h3 className="font-medium text-monochrome-20">Get PEAQ rewards. Coming soon.</h3>
          </div>
        </div>
        <Button transparent={true} onClick={handleOpenModal}>
          <IcArrowNext />
        </Button>
      </div>
      {userData && showModal && <ClaimReaqRewardModal handleClose={handleCloseModal} user={userData} />}
    </>
  );
};

export default PeaqRewardingCampaign;
