import React from 'react'

const IcFilter = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.39673 5.7384H13.1581" stroke="#9E9E9E" strokeLinecap="square" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.74007 5.69843C6.74007 4.7371 5.9554 3.95776 4.98673 3.95776C4.01873 3.95776 3.2334 4.7371 3.2334 5.69843C3.2334 6.65976 4.01873 7.43906 4.98673 7.43906C5.9554 7.43906 6.74007 6.65976 6.74007 5.69843Z" stroke="#9E9E9E" strokeLinecap="square" />
            <path d="M7.73693 12.1472H3.97559" stroke="#9E9E9E" strokeLinecap="square" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3934 12.1064C10.3934 11.1451 11.1781 10.3657 12.1468 10.3657C13.1148 10.3657 13.9001 11.1451 13.9001 12.1064C13.9001 13.0677 13.1148 13.8477 12.1468 13.8477C11.1781 13.8477 10.3934 13.0677 10.3934 12.1064Z" stroke="#9E9E9E" strokeLinecap="square" />
        </svg>

    )
}

export default IcFilter