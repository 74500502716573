import { ITransaciton } from "@/types/transactions";
import dayjs from "dayjs";
import web3 from "web3";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

interface ITransactionItemProps {
    item: ITransaciton;
    key: number;
    onClick: () => void;
    isSelected: boolean;
}

const TransactionItem = ({ item, key, onClick, isSelected }: ITransactionItemProps) => {
    // Parse the date with the correct format
    const parsedDate = dayjs(item.date, "D/M/YYYY, h:mm:ss a");

    return (
        <div className={`grid grid-cols-5 text-white py-3 border-b border-monochrome-40 cursor-pointer ${isSelected ? 'bg-green-10' : ''}`} key={key} onClick={onClick}>
            <span>Token transfer</span>
            <span className="text-end">{item.tokenSymbol}</span>
            <span className="text-end">{dayjs(item.date).format("DD/MM/YYYY")}</span>
            <span className="text-end">{dayjs(item.date).format("hh:mm A")}</span>
            <span className="text-end">${web3.utils.fromWei(item.tokenAmount.toString(), "ether")}</span>
        </div>
    )
}

export default TransactionItem;
