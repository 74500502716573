import React, { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { formatYYYYMMDD } from "../../utils";
import ProjectDocs from "./ProjectDocs";
import StepOneForm from "./StepOneForm";
import StepThreeForm from "./StepThreeForm";
import StepTwoForm from "./StepTwoForm";

interface ProjectViewProps {
  project: any;
  close: () => void;
}

const ProjectView = ({ project, close }: ProjectViewProps) => {
  // Asset parameters
  const [assetType] = useState(project.assetIds[0].assetType || "");
  const [technology] = useState(project.assetIds[0].technology || "");
  const [location] = useState(project.location || "");
  const [specificDetails] = useState(project.assetIds[0].specificDetails || {});
  const [operationStart] = useState(formatYYYYMMDD(project.operationStart) || "");
  const [operationLifetime] = useState(project.operationLifetime || "");
  // Project parameters
  const [projectName] = useState(project.name || "");
  const [projectDescription] = useState(project.projectDescription || "");
  const [financingStructure] = useState(project.financingStructure || "");

  const [netPresentValue] = useState(project.npv || "");
  const [internalRateOfReturn] = useState(project.irr || "");
  const [levelizedCostOfEnergy] = useState(project.lcoe || "");
  const [paybackPeriod] = useState(project.paybackPeriod || "");
  const [capacityFactor] = useState(project.capacityFactor || "");

  const [revenueStreams] = useState(project.revenueStreams || []);
  const [operationCosts] = useState(project.operationCosts || []);

  const [fundingGoal] = useState(project.fundingGoal || "");
  const [fundingUsage] = useState(project.fundingUsage || [{ amount: "", description: "" }]);
  const [tokenAmount] = useState(project.tokenPrice || "");
  const [tokenPrice] = useState(project.tokenPrice || "");
  const [revenueShare] = useState(project.revenueShare || "");
  const [contractStartDate] = useState(project.contractStartDate || "");
  const [contractTerm] = useState(project.contractTerm || "");

  return (
    <Modal show={true} onHide={close} size="xl" data-bs-theme="dark" centered>
      <Modal.Header closeButton>
        <Modal.Title>Project Details: {project.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey="0" className="w-full">
          <Accordion.Item eventKey="0">
            <Accordion.Header>General Information</Accordion.Header>
            <Accordion.Body>
              <StepOneForm
                {...{
                  assetType,
                  projectName,
                  projectDescription,
                  technology,
                  location,
                  specificDetails,
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Valuation Data</Accordion.Header>
            <Accordion.Body>
              <StepTwoForm
                {...{
                  financingStructure,
                  netPresentValue,
                  internalRateOfReturn,
                  levelizedCostOfEnergy,
                  paybackPeriod,
                  capacityFactor,
                  revenueStreams,
                  operationCosts,
                  operationStart,
                  operationLifetime,
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Tokenization Data</Accordion.Header>
            <Accordion.Body>
              <StepThreeForm
                {...{
                  fundingGoal,
                  tokenAmount,
                  tokenPrice,
                  fundingUsage,
                  revenueShare,
                  contractStartDate,
                  contractTerm,
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Documents</Accordion.Header>
            <Accordion.Body className="py-4 px-0 md:px-10">
              <ProjectDocs id={project._id} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary" onClick={close}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectView;
