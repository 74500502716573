import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import useAuth from "@/hooks/useAuth";
import { classNames } from "@/utils";
import { ROUTES } from "@constants";
import useStore from "@store/useStore";
import ContactSupportModal from "./ContactSupport";
import Logout from "./Logout";

import logo from "../assets/penomo_logo_white.svg";
import "./NavBar.css";
import { NavBarList } from "@/constants/NavMenu";
import { IcClose, IcHamburger } from "@/assets/menu";
import ContactSupportSucessModal from "./Page/ContactSupport/SucessModal";

const NavBar = () => {

  const { pathname } = useLocation();
  const { userId } = useAuth();
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navButtons = document.querySelectorAll(".nav-button");
  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
  const navigate = useNavigate()

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const handleCloseContactModal = (isSuccess: boolean) => {
    setShowSuccessDialog(isSuccess);
    setShowContactModal(false);
    navigate(ROUTES.DASHBOARD)
  };

  const handleCloseMobileMenu = (item: any = null) => {

    if(item?.link === ROUTES.CONTACTSUPPORT) {
      setShowContactModal(true)
    } else {
      navButtons.forEach((button) => button.classList.remove("active"));

      if (window.matchMedia("screen and (max-width: 1024px)").matches) {
        setIsNavOpen(!isNavOpen);
      }
    }
  };

  if (userId && isSetupProfilePage) {
    return null;
  }

  return (
    <>
      <nav className={classNames("w-full lg:w-72 bg-[#1A1C22]", { hidden: !userId })}>
        <div className="h-full max-w-screen-xl flex flex-row lg:flex-col lg:content-between flex-wrap pl-8 lg:py-5">
          <div className="w-full grow">
            <div className={"h-[88px] w-full flex justify-between items-center lg:items-start"}>
              <NavLink to={ROUTES.DASHBOARD} className="flex">
                <img src={logo} className="navbar-logo h-7" alt="penomo" />
              </NavLink>
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="borderinline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden"
                aria-controls="navbar-default"
                aria-expanded="false"
                onClick={() => handleCloseMobileMenu()}
              >
                {isNavOpen == true ? <IcClose /> : <IcHamburger />}
              </button>
            </div>

            <div className={classNames("hidden w-full z-50", [{ "mobile-menu": isNavOpen }], "lg:block")} id="navbar-default">
              <ul className="pl-8 py-4 lg:pl-0 lg:py-0 bg-[#1A1C22] font-medium flex flex-col w-full gap-y-8 lg:justify-center">
                {userId && !isSetupProfilePage && (
                  <>
                  {NavBarList.map((item, index) => {
                      return (
                        <li key={"menu-" + index}>
                          <NavLink
                            as={NavLink}
                            to={item.link}
                            className={`btn-tertiary-navbar flex justify-between`}
                            onClick={() => handleCloseMobileMenu(item)}
                          >
                            <div className={"flex justify-items-center"}>
                              {pathname == item.link
                                ? item.iconWhenSelected
                                : item.icon}
                              <span className={`pl-2 flex my-auto`}>
                                {item.title}
                              </span>
                            </div>
                            <p
                              className={"w-[0.25rem] h-5  bg-[#00CC9C] hidden"}
                            ></p>
                          </NavLink>
                        </li>
                      );
                    })}
                    <li className="lg:hidden">
                      <Logout />
                    </li>
                    {/* <li>
                    <NavLink as={NavLink} to="/dashboard" className="btn-tertiary-navbar " onClick={handleCloseMobileMenu}>
                        <IoHomeOutline className="nav-icon" /> &nbsp; Dashboard
                        <p></p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/marketplace" className="btn-tertiary-navbar nav-button" onClick={handleCloseMobileMenu}>
                      <FiShoppingCart className="nav-icon" /> &nbsp; MarketPlace
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/transactions" className="btn-tertiary-navbar nav-button" onClick={handleCloseMobileMenu}>
                      <TbReportMoney className="nav-icon" /> &nbsp; Transactions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/settings" className="btn-tertiary-navbar nav-button" onClick={handleCloseMobileMenu}>
                      <IoSettingsOutline className="nav-icon" /> &nbsp; Settings
                    </NavLink>
                  </li>
                  <li> */}
                    {/* {userData?.walletAddress ? (
                      <NavLink to={"/tokensale"} className="btn-tertiary-navbar nav-button" onClick={handleCloseMobileMenu}>
                        <MdOutlineGeneratingTokens className="nav-icon" /> &nbsp; Buy PreSale Token
                      </NavLink>
                    ) : (
                      <button
                        onClick={() => toast.error("You have to first enter metamask address to buy Penomo presale tokens")}
                        className="btn-tertiary-navbar nav-button w-[95%]"
                      >
                        <MdOutlineGeneratingTokens className="nav-icon" /> &nbsp; Buy PreSale Token
                      </button>
                    )} */}
                    {/* </li> */}
                    {/* <li>
                    <button onClick={handleOpenContactModal} className="btn-tertiary-navbar nav-button w-full">
                      <MdOutlineContactSupport className="nav-icon" /> &nbsp; Contact Support
                    </button>
                  </li>
                  <li>
                    <Logout />
                  </li> */}
                  </>
                )}
              </ul>
            </div>
          </div>

          <div className="hidden lg:block">
            <Logout />
          </div>
        </div>
      </nav>
      {showContactModal && <ContactSupportModal open={showContactModal} onClose={handleCloseContactModal} />}
      <ContactSupportSucessModal show={showSuccessDialog} handleClose={() => setShowSuccessDialog(false)}/>
    </>
  );
};

export default NavBar;
