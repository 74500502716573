import React from "react";

interface IStepOneForm {
  assetType: string;
  projectName: string;
  projectDescription: string;
  technology: string;
  location: string;
  specificDetails: {
    capacityMw: number;
    annualProductionMwh: number;
    gridIntegration: boolean;
    capacityMwh: number;
    chargeDischargeRateMw: number;
    chargePointType: string;
    powerOutputKw: number;
    annualOutputMwh: number;
  };
}

const StepOneForm = ({ assetType, projectName, projectDescription, technology, location, specificDetails }: IStepOneForm) => {
  return (
    <div className="step1">
      <div className="form-group">
        <label htmlFor="projectName">Project Name: {projectName}</label>
      </div>

      <div className="form-group">
        <label htmlFor="projectDescription">Project Description: {projectDescription}</label>
      </div>

      <div className="form-group">
        <label htmlFor="assetType">Asset Type: {assetType}</label>
      </div>

      <div className="form-group">
        <label htmlFor="technology">Technology: {technology}</label>
      </div>

      <div className="form-group">
        <label htmlFor="location">Location: {location}</label>
      </div>

      {/* Specific details input fields based on asset type */}
      {assetType === "Solar" && (
        <>
          <div className="form-group">
            <label htmlFor="capacityMw">Installed Capacity: {specificDetails.capacityMw} MW</label>
          </div>
          <div className="form-group">
            <label htmlFor="annualProductionMwh">Annual Energy Production: {specificDetails.annualProductionMwh} MWh</label>
          </div>
          <div className="form-group">
            <label htmlFor="gridIntegration">Grid Integration : {specificDetails.gridIntegration ? "Yes" : "NO"}</label>
          </div>
        </>
      )}

      {assetType === "Wind" && (
        <>
          <div className="form-group">
            <label htmlFor="capacityMw">Installed Capacity: {specificDetails.capacityMw} MW</label>
          </div>
          <div className="form-group">
            <label htmlFor="annualProductionMwh">Annual Energy Production: {specificDetails.annualProductionMwh} MWh</label>
          </div>
          <div className="form-group">
            <label htmlFor="gridIntegration">Grid Integration: {specificDetails.gridIntegration ? "Yes" : "No"}</label>
          </div>
        </>
      )}

      {assetType === "Battery" && (
        <>
          <div className="form-group">
            <label htmlFor="capacityMwh">Storage Capacity: {specificDetails.capacityMwh} MWh</label>
          </div>
          <div className="form-group">
            <label htmlFor="chargeDischargeRateMw">Charge/Discharge Rate: {specificDetails.chargeDischargeRateMw} MW</label>
          </div>
          <div className="form-group">
            <label htmlFor="gridIntegration">Grid Integration: {specificDetails.gridIntegration ? "Yes" : "No"} </label>
          </div>
        </>
      )}

      {assetType === "ChargePoint" && (
        <>
          <div className="form-group">
            <label htmlFor="chargePointType">Charge Point Type: {specificDetails.chargePointType}</label>
          </div>
          <div className="form-group">
            <label htmlFor="powerOutputKw">Power Output: {specificDetails.powerOutputKw} kW</label>
          </div>
          <div className="form-group">
            <label htmlFor="annualOutputMwh">Annual Output: {specificDetails.annualOutputMwh} MWh</label>
          </div>
        </>
      )}
    </div>
  );
};

export default StepOneForm;
