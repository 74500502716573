import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { fetchUser, updateUser } from "@/services/api";
import useStore from "@/store/useStore";
import useAuth from "@/hooks/useAuth";
import useWebSocket from "@/hooks/useWebSocket";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import IcMergeCopy from "@/assets/ic_merge_copy";
import IcEdit from "@/assets/ic_edit";
import KycVerificationBanner from "@/components/KycVerificationBanner";
import KycStatus from "./KycStatus";
import Loader from "@/components/Loader";

const userDataSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email format"),
});

const AccountDetails = () => {
  const { setUser } = useStore();
  const { userId } = useAuth();

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [initialFormData, setInitialFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [kycStatus, setKycStatus] = useState<string>("Not Submitted");

  useWebSocket(userId, () => {
    console.log("Refetch from WebSocket Occured");
    refetch();
  });

  const isVerified = userData?.kycStatus === "Verified";

  useEffect(() => {
    if (userData) {
      const _userData = {
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
      };
      setKycStatus(userData.kycStatus);
      setFormData(_userData as any);
      setInitialFormData(_userData);
    }
  }, [userData]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const result = userDataSchema.safeParse(formData);
    if (!result.success) {
      toast.error("Please correct the errors before submitting.");
      return;
    }

    try {
      setIsSubmitting(true);
      const response: any = await updateUser(userId, formData);
      setIsSubmitting(false);
      if (response.code == 200) {
        setFirstNameFieldEditable(false);
        setLastNameFieldEditable(false);
        setUser({
          ...response.data,
          walletDetails: userData?.walletDetails,
        });
        toast.success("User details updated successfully");
        setInitialFormData(formData);
      } else {
        toast.error("Failed to update user details.");
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error updating user details:", error);
      toast.error("Failed to update user details.");
    }
  };

  const showCopiedToast = (message: string) => {
    toast.success(message);
  };

  const [isFirstNameFieldEditable, setFirstNameFieldEditable] = useState(false);
  const [isLastNameFieldEditable, setLastNameFieldEditable] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-monochrome-20 text-[#9E9E9E]">Account</h4>
      <div className="flex flex-col sm:flex-row bg-monochrome-100 p-4 rounded divide-y sm:divide-y-0 sm:divide-x divide-monochrome-40">
        <div className="flex flex-col gap-2 sm:w-4/12 pb-4 md:pb-0">
          <h3 className="text-[0.875rem]">Basic Details</h3>
          <p className="text-monochrome-20 text-[0.688rem]">Manage essential account information</p>
        </div>
        <div className="flex flex-col gap-4 sm:pl-6 sm:w-8/12 mobile-space">
          <div className="relative flex flex-col gap-3">
            <label className="text-[0.688rem]">First Name</label>
            <input
              type="text"
              name="firstName"
              placeholder="Enter your first name here"
              className={`w-full px-0 placeholder-[#9E9E9E]  !rounded-none ${
                isFirstNameFieldEditable ? "px-2" : "bg-transparent !border-b border-monochrome-40"
              }`}
              disabled={!isFirstNameFieldEditable}
              value={formData.firstName}
              onChange={handleChange}
            />
            {isFirstNameFieldEditable ? (
              <div
                className="absolute top-[45px] right-2 cursor-pointer text-green-500"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {isSubmitting ? <Loader isLoading={true} /> : "Save"}
              </div>
            ) : (
              <div className="absolute top-[38px] right-2 cursor-pointer text-green-500" onClick={() => setFirstNameFieldEditable(true)}>
                <IcEdit />
              </div>
            )}
          </div>
          <div className="relative flex flex-col gap-3">
            <label className="text-[0.688rem]">Last Name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Enter your last name here"
              className={`w-full px-0 placeholder-[#9E9E9E] !rounded-none ${
                isLastNameFieldEditable ? "px-2" : "bg-transparent !border-b border-monochrome-40"
              }`}
              disabled={!isLastNameFieldEditable}
              value={formData.lastName}
              onChange={handleChange}
            />
            {isLastNameFieldEditable ? (
              <div
                className="absolute top-[45px] right-2 cursor-pointer text-green-500"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {isSubmitting ? <Loader isLoading={true} /> : "Save"}
              </div>
            ) : (
              <div className="absolute top-[38px] right-2 cursor-pointer text-green-500" onClick={() => setLastNameFieldEditable(true)}>
                <IcEdit />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-[0.688rem]">Email</label>
            <input type="email" placeholder="Enter your email here" value={formData.email} disabled className="w-full bg-transparent !text-monochrome-20 p-0" />
          </div>
          <div className="relative flex flex-col gap-3">
            <label className="mt-3 text-[1rem]">KYC Status</label>
            <KycStatus status={kycStatus} />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row bg-monochrome-100 p-4 rounded divide-y md:divide-y-0 md:divide-x divide-monochrome-40">
        <div className="flex flex-col gap-2 md:w-4/12 pb-4 md:pb-0">
          <h3 className="text-[0.875rem]">Wallet Details</h3>
          <p className="text-monochrome-20 text-[0.688rem]">Your wallet address</p>
        </div>
        <div className="flex flex-col md:pl-6 md:w-8/12">
          <label className="text-monochrome-20 text-[0.688rem]">Wallet Address</label>
          <div className="flex items-center gap-4 md:mt-2 justify-between">
            <p className="text break-words text-monochrome-20 text-[0.688rem]">{userData?.walletDetails?.web3authWalletAddress}</p>
            <CopyToClipboard text={userData?.walletDetails?.web3authWalletAddress || ""} onCopy={() => showCopiedToast("Address copied to clipboard!")}>
              <div className="cursor-pointer text-green-500">
                <IcMergeCopy />
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <KycVerificationBanner isVerified={isVerified} />
    </div>
  );
};

export default AccountDetails;
