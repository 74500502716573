import { fetchProjectById } from '@/services/api';
import InvestmentCard from './MarketplaceCard/InvestmentCard'
import Tabs from './Tabs/Tabs'
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export default function MarketplaceDetails() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId') || "";

  const { data: companyData, isLoading: isLoadingUser } = useQuery({
    queryKey: ["getCompaney", projectId],
    queryFn: () => fetchProjectById(projectId),
    enabled: !!projectId,
  });

  return (
    <div>
      <InvestmentCard companyData={companyData} />
      <Tabs companyData={companyData}/>
    </div>
  )
}
