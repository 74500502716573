import Footer from "@components/Footer";
import Login from "@components/Login";
import logo from "../assets/penomo_logo_white.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const HomePage = () => {

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  /**
 * Checks if the user is already logged in by checking the existence of authToken and web3AuthToken in localStorage.
 * If both tokens exist, it sets the isLoggedIn state to true.
 */
  const navigate = useNavigate();
  useEffect(() => {
    // Check local storage for existing tokens
    const authToken = localStorage.getItem('authToken');
    const web3AuthToken = localStorage.getItem('web3AuthToken');
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
      navigate('/dashboard');
    }
  }, [navigate]);
  return (
    <div className="flex home-main gap-4 h-full">
      {!isLoggedIn && (
        <div className="w-2/4 hidden xl:flex flex-col h-full gap-4 p-4">
          <div className="flex w-full h-1/3 gap-4">
            <img className="w-1/3 rounded overflow-hidden" src={"./assets/dashboard/soloar.png"} />
            <img className="w-2/3 rounded overflow-hidden" src={"./assets/dashboard/green_3.png"} />
          </div>
          <div className="flex w-full h-1/3 gap-4">
            <img className="w-1/3 rounded overflow-hidden" src={"./assets/dashboard/green_1.png"} />
            <img className="w-2/3 rounded overflow-hidden" src={"./assets/dashboard/wind.png"} />
          </div>
          <div className="flex w-full h-1/3 gap-4">
            <img className="w-1/3 rounded overflow-hidden" src={"./assets/dashboard/chargin_station.png"} />
            <img className="w-1/3 rounded overflow-hidden" src={"./assets/dashboard/green_2.png"} />
            <img className="w-1/3 rounded overflow-hidden" src={"./assets/dashboard/ev_charging_point.png"} />
          </div>
        </div>
      )}

      <div className={`w-full ${!isLoggedIn ? "xl:w-2/4" : ""} flex flex-col items-center justify-center`}>
        <img src={logo} className="h-[48px] md:w-67 h-14 mb-4" alt="penomo logo" />
        <p className="mb-8 text-[1rem] text-center leading-5 sm:leading-3">Invest in green assets and own the energy transition.</p>
        <Login />
        {!isLoggedIn && (
          <p className="mt-6" style={{ fontSize: "12px", color: "#8E8E8E" }}>
            Haven&apos;t registered? Log in to create an account
          </p>
        )}
      </div>
    </div>
    // <div className="home-page mx-10 md:mx-0 my-10 lg:-ml-72">
    //   <div className="flex flex-col items-center justify-center">
    //     <img data-testid="logo" src={logo} className="w-72 md:w-96 h-20" alt="penomo logo" />
    //     <h3 className="pt-3 text-xl text-center">Invest into renewable energy assets.</h3>
    //   </div>
    //   <div className="flex items-center justify-center">
    //     <div className="vh-center">
    //       <Login />
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
  );
};

export default HomePage;
